import AppConfig from "../AppConfig"

const PCloudHelper = {

    urlVideoContent(videoPath) {
        let config = AppConfig.config("pcloud");
        return config.contentUrl.concat(videoPath).concat(".mp4#t=1.5");
    },

    urlHtmlContent(docPath) {
        if(docPath != null) {
            let config = AppConfig.config("pcloud");
            return config.contentUrl.concat(docPath).concat(".htm");
        }
    }
};

export default PCloudHelper;

import React from "react";
import {matchPath} from "react-router";
import VideoCatalogManager from "../EntityManager/VideoCatalogManager";
import SubjectCatalogManager from '../EntityManager/SubjectCatalogManager';
import {BackLink, ContentLink, RoutedHashLink, BreadcrumbSection} from "../View/Links";
import WaitView from "../View/WaitView";
// import MartialScienceDoc from "../Documents/MartialScienceDoc";

function SubjectItemLinkHolder(props) {
    return (
        <div style={{paddingLeft: "0.2em", marginTop: "0.25em", marginBottom: "0.25em", paddingTop: "0.25em", paddingBottom: "0.25em"}}>
            {props.children}
        </div>
    );
}

function SubjectItemDocumentLink(props) {
  let subject = props.subject;
  let item = props.item;
  let pathTo = ["/learn", subject.id(), "read", item.fk].join("/");
  let label = subject.findDocument(item.fk).display;
  return (
    <SubjectItemLinkHolder>
      <ContentLink to={pathTo}>{label}</ContentLink>
    </SubjectItemLinkHolder>
  );
}

function SubjectItemVideoLink(props) {
  let subject = props.subject;
  let item = props.item;
  let pathTo = ["/learn", subject.id(), "video", item.fk].join("/");
  let label = VideoCatalogManager.catalog().video(item.fk).label();
  return (
    <SubjectItemLinkHolder>
      <ContentLink to={pathTo}>{label}</ContentLink>
    </SubjectItemLinkHolder>
  );
}

function SubjectModuleGroupItemView(props) {
  if(props.item.dt === "video") {
    return <SubjectItemVideoLink subject={props.subject} item={props.item} />
  }
  if(props.item.dt === "document") {
    return <SubjectItemDocumentLink subject={props.subject} item={props.item} />
  }
}

function SubjectModuleGroupView(props) {
  let group = props.group;
  let groupDisplay;
  if(group.display) {
    groupDisplay = <h4>{group.display}</h4>;
  }
  let idx=0;
  return (
    <div>
      {
        groupDisplay
      }
      {
        group.items.map(item => {

          let key = [props.uid, idx++].join("-");
          return <SubjectModuleGroupItemView key={key} uid={key} subject={props.subject} item={item} />;
        })
      }
    </div>
  );
}

function SubjectModuleView(props) {
  let module = props.module;
  let idx = 0;
  return (
    <div key={module.display}>
      <h3>{module.display}</h3>
      <div className="indent">
        {
          module.groups.map(group => {
            let key = [props.uid, idx++].join("-");
            return <SubjectModuleGroupView key={key} uid={key} subject={props.subject} group={group} />;
          })
        }
      </div>
    </div>
  );
}

function SubjectView(props) {
  let subject = SubjectCatalogManager.catalog().subject(props.subjectId);

  let subjectDocLink;
  if(subject.hasDocument()) {
    let doc = subject.document();
    let pathTo = ["/learn", subject.id(), "read", subject.documentFk()].join("/");
    subjectDocLink = <ContentLink to={pathTo}>{doc.display}</ContentLink>;
  }

  let idx = 0;
  return (
    <div>
      <h2>{subject.label()}</h2>
      {
        subjectDocLink
      }
      {
        subject.allModules().map( module => {
          let key = [subject.id(), idx++].join("-");
          return <SubjectModuleView key={key} uid={key} subject={subject} module={module} />;
        })
      }
      <BackLink to="/learn" />
    </div>
  );
}

export default class CurriculumSubjectPage extends React.Component {
  breadcrumbs = {
    "principles" : { id: "foundations", label: "Foundations" },
    "footwork"   : { id: "foundations", label: "Foundations" },
    "patterns"   : { id: "foundations", label: "Foundations" },
    "tumbling"   : { id: "foundations", label: "Foundations" },

    "strikes"   : { id: "cross-functional-skills", label: "Cross-Functional Skills" },
    "locks"     : { id: "cross-functional-skills", label: "Cross-Functional Skills" },
    "grips"     : { id: "cross-functional-skills", label: "Cross-Functional Skills" },
    "takedowns" : { id: "cross-functional-skills", label: "Cross-Functional Skills" },
      
    "streetboxing"  : { id: "putting-it-together", label: "Putting it Together" },
    "escrima"       : { id: "putting-it-together", label: "Putting it Together" },
    "walking-stick" : { id: "putting-it-together", label: "Putting it Together" },
      
    "martial-science" : { id: "academic-topics", label: "Academic Topics" }, 
    "kempo"           : { id: "academic-topics", label: "Academic Topics" },
    "hepiryu"         : { id: "academic-topics", label: "Academic Topics" } 
  };

  constructor(props) {
    super(props);
    this.state = { isCatalogLoaded: false };
  }

  componentDidMount() {
    SubjectCatalogManager.waitAllLoaded().then( () => this.setState({isCatalogLoaded: true}) );
  }

  render() {
    let match = matchPath(this.props.location.pathname, { path: "/learn/:subjectId"});
    if(this.state.isCatalogLoaded !== true)  {
      return <WaitView />;
    } else {
      if(match === null) {
        return;
      } else {
        let subjectId = match.params.subjectId;
        let subjectGroup = this.breadcrumbs[subjectId];
        return (
          <BreadcrumbSection>
            <RoutedHashLink to={"/learn#".concat(subjectGroup.id)}>{subjectGroup.label}</RoutedHashLink>
            <SubjectView subjectId={subjectId} />
          </BreadcrumbSection>
        );
      }
    }
  }
};

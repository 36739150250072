import "./Links.css";
import React from "react";
import {NavLink} from "react-router-dom";
import {HashLink as RoutedHashLinkImpl} from "react-router-hash-link";

export function Whitespace(props) {
  const horzSpace = <span>&nbsp;</span>;
  return horzSpace;
}

export function DefaultLinkIcon(props) {
  const contentIcon = <span style={{fontWeight: 600}}>&raquo;</span>;
  return contentIcon;
}

export function LinkIcon(props) {
  const imageAlt = (props.alt) ? props.alt : "";
  return (
    <span className="linkIcon">
      <img alt={imageAlt} src={props.src} />
    </span>
  );
}

export function ExternLink(props) {
  return (
    <a href={props.to} target="blank">
      {(props.label) ? props.label : props.children}
      <Whitespace />
      <LinkIcon src="/img/glyph/extern.svg" />
    </a>
  );
}

export function HashLink(props) {
  return (
    <a href={(props.to.startsWith("#")) ? props.to : "#".concat(props.to)}>
      {(props.label) ? props.label : props.children}
      <Whitespace />
      <DefaultLinkIcon />
    </a>
  );
}

export function RoutedHashLink(props) {
  return (
    <RoutedHashLinkImpl to={props.to}>
      {(props.label) ? props.label : props.children}
      <Whitespace />
      <DefaultLinkIcon />
    </RoutedHashLinkImpl>
  );
}

export function ContentLink(props) {
  return (
    <NavLink to={props.to}>
      {(props.label) ? props.label : props.children}
      <Whitespace />
      <DefaultLinkIcon />
    </NavLink>
  );
}

export function TopLink() {
  return (
    <div className="centerLink">
      <HashLink to="#top">Top</HashLink>
    </div>
  );
}

export function BackLink(props) {
  return (
    <div className="centerLink">
      <ContentLink to={props.to}>Back</ContentLink>
    </div>
  );
}

export function TocLink(props) {
  return (
    <div className="tocLink">
      <ContentLink to={props.to}>
        {(props.label) ? props.label : props.children}
      </ContentLink>
    </div>
  );
}

export function ComingSoon(props) {
  return (
    <div className="tocLink">
      {(props.label) ? props.label : props.children}
      <Whitespace />
      <span>(coming soon)</span>
    </div>
  )
}

export function BreadcrumbSection(props) {
  return (
    <div className="breadcrumb">
      {props.children}
    </div>
  )
}

/* obsolete
export function DocumentLink(props) {
  const linkLabel = (props.label) ? props.label : props.children;
  const image = (
    <span style={{display: "inline-block", verticalAlign: "middle"}}>
      <img alt="" src="/img/glyph/documents.svg" style={{height: "1.5em"}} />
    </span>
  );
  return <NavLink to={props.to}>{image}<Whitespace />{linkLabel}</NavLink>;
}
*/

/* obsolete
export function VideoLink(props) {
  const linkLabel = (props.label) ? props.label : props.children;
  const image = (
    <span style={{display: "inline-block", verticalAlign: "middle"}}>
      <img alt="" src="/img/glyph/video-light.svg" style={{height: "1em"}} />
    </span>
  );
  return <NavLink to={props.to}>{image}<Whitespace />{linkLabel}</NavLink>;
}
*/

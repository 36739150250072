import React from "react";
import {matchPath} from "react-router";
import {BackLink, TocLink, ContentLink, BreadcrumbSection, RoutedHashLink, ComingSoon} from "../View/Links";
import OutsideTheBox from "../Documents/OutsideTheBox";

function TopicLink(props) {
    let path = ["/learn/martial-science", props.topicId].join("/");
    return (
        <TocLink to={path}>
            {(props.label) ? props.label : props.children}
        </TocLink>
    );
}

const martialScienceTopics = {
    "index": (
        <div>
            <p>Reflex Fighting Arts leans heavily on applied science. Here's the basics.</p>

            <h2>Geometry</h2>
            <div className="indent">
                <TopicLink topicId="center-of-rotation">Center of Rotation</TopicLink>
                <TopicLink topicId="triangles">Triangles</TopicLink>
            </div>

            <h2>Physics</h2>
            <div className="indent">
                <TopicLink topicId="leverage">Leverage</TopicLink>
                <TopicLink topicId="freefall">Freefall</TopicLink>
                <TopicLink topicId="inertia">Inertia</TopicLink>
            </div>

            <h2>Anatomy</h2>
            <div className="indent">
                <TopicLink topicId="musculo-skeletal-system">Musculoskeletal System</TopicLink>
                <TopicLink topicId="nervous-system">Nervous System</TopicLink>
                <TopicLink topicId="vision-system">Vision System</TopicLink>
            </div>

            <h2>Kinesiology</h2>
            <div className="indent">
                <TopicLink topicId="antagonistic-muscles">Antagonistic Muscles</TopicLink>
                <TopicLink topicId="proprioception">Proprioception</TopicLink>
            </div>

            <h2>Reflex Physiology</h2>
            <div className="indent">
                <TopicLink topicId="reflex-physiology">Reflex Physiology</TopicLink>
                <TopicLink topicId="motor-skills">Motor Skills</TopicLink>
                <TopicLink topicId="flinch-reflex">Flinch Reflex</TopicLink>
                <TopicLink topicId="balance-reflex">Balance Reflex</TopicLink>
                <TopicLink topicId="cross-extensor-reflex">Cross Extensor Reflex</TopicLink>
                <TopicLink topicId="myotatic-reflex">Myotatic Reflex</TopicLink>
                <TopicLink topicId="fight-flight-response">Fight-Flight Response</TopicLink>
                <TopicLink topicId="reflex-syncope">Reflex Syncope</TopicLink>
            </div>

            <h2>Cognition</h2>
            <div className="indent">
                <TopicLink topicId="problem-solving">Problem Solving</TopicLink>
                <ComingSoon topicId="pattern-interrupts">Pattern Interrupts</ComingSoon>
                <ComingSoon topicId="conflict-management">Conflict Management</ComingSoon>
            </div>
        </div>
    ),
    "antagonistic-muscles":  (
        <div>
            <h2>Antagonistic Muscles</h2>
            <p>The joints in the body are moved by pairs of opposing muscles; one which flexes the joint, and another which extends it. These muscles are called antagonistic muscles or antagonistic groups.</p>
            <h3>Concepts &amp; Key Points</h3>
            <div>
                <p><em>Muscle Inhibition</em> - For a muscle to move a joint, not only must it contract, but the antagonistic muscle must be <em>prevented</em> from contracting by the autonomic nervous system,</p>
                <p><em>Range of Motion</em> - As the joint moves towards the end of its range of motion in one direction, the stretch in the antagonistic muscle increases, eventually stopping further motion of the joint.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Overcoming Strength</em> - Muscles can be released from a contracted state by triggering the antagonistic muscle to contract by triggering either the deep tendon or the stretch reflex in the opposing muscle.</p>
                <p><em>Releasing the Grip</em> - Flexing the wrist opens the grip because it pulls on the tendons which extend the fingers. To remove an opponent's grip from say, clothing, or even a weapon, techniques which flex the wrist are often more effective than those which rely on either pain or strength to open the fingers.</p>
            </div>
        </div>
    ),
    "balance-reflex": (
        <div>
            <h2>Balance Reflex</h2>
            <p>This is another spinal reflex intended to both prevent falling and to minimize injury when falling is unavoidable. It progresses through three stages as the loss of balance becomes more severe.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Righting Reflex</em> - When balance is disturbed by a small amount, the righting reflex tries to regain it by reacting in the opposite direction: You try to &lsquo;right&rsquo; youself.</p>
                <p><em>Compensating Reflex</em> - The compesating reflex moves the head, arms, legs, and torso in a way that tries to compensate for the movement taking you off-balance. Usually this involves taking a step to prevent further motion in the direction you were moved, or swinging your arms to acheive a counterbalance.</p>
                <p><em>Catching Reflex</em> - When a fall is imminent, the catching reflex tries to minimize the damage upon impact.</p>
                <p><em>Ordered Response</em> - The balance reflex progresses in the following order:</p>
                <ul>
                    <li>Fingers extend and fan out,</li>
                    <li>Arms move away from the body,</li>
                    <li>Arms reach in the direction of the fall,</li>
                    <li>Wrists move into a position of extension.</li>
                </ul>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Open the Grip</em> - The catching reflex opens the fingers, which means it can release an opponent&rsquo;s grip without the need to rely on either strength or pain.</p>
                <p><em>Neutralize their Strength</em> - When an opponent is off-balance, it compromizes their ability to use strength while striking or grabbing. By controlling their balance we can temporarily neutralize their strength advantage over us.</p>
                <p><em>Get them to Help</em> - Many throws take the opponent forwards or backwards, but the opponent will resist our attempts move them. We can use this to our advantage. If we first tug them in the opposite direction from the one we actually want, their righting reflex will give us an assist by causing them to tilt in the direction we want.</p>
                <p><em>Stop a Punch</em> - The compensating reflex causes and opponent to moving their arms out and away from the body in an attempt to counter-balance. This means we can stop an opponent's punch by using their opponsite arm to control their balance. This is an especially useful tactic to keep an opponent's knife-wielding hand away from us.</p>
                <p><em>Footwork</em> - Footwork can be difficult for beginners to learn, and under stress even advanced practitioners can find their feet don't move fast enough. But we can leverage the balance reflex to help get the feet moving. A small twitch of the head in any direction not only helps evade incoming blows, it also triggers the balance reflex, and the feet will move to catch up. In other words, instead of initiating footwork with the feet, start by twitching the head.</p>
                <p><em>Hands Up</em> - Like using the balance reflex to initiate footwork, we can also use it to get our hands up into a defensive position. It's common for beginners to drop their hands between punches, and advanced practitioners can be taken off-guard in an actual confrontation. Since the balance reflex uses the arms for counter balance, twiching the head will accelerate getting the hands up to a defensive/offensive position.</p>
            </div>
        </div>
    ),
    "center-of-rotation":  (
        <div>
            <h2>Center of Rotation</h2>
            <p>The circumference of a circle is related to it's diameter. The larger the diameter, the larger the circumference. So in a single revolution, a small diameter wheel travels less distance than a large diameter wheel.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Distance Traveled</em> - Points on the outer edge of the wheel (e.g. bicycle, automobile) travel farther say, in feet, in a single rotation than points closer to the center of the wheel.</p>
                <p><em>Speed vs. Distance</em> -  Since points on the edge of the wheel travel farther in each rotation, they have to travel faster, in miles per hour, than points closer to the center of the wheel.</p>
                <p><em>Disproportional Speed</em> - Actually, points the edge of the wheel move ALOT faster. Speed increases with the distance from the center, squared, so a point on the edge travels almost 5X faster than a point half the distance from the center!</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Grabbing a Wrist</em> - "Snatching" an opponent's wrist in order to say, apply a wrist lock, is notoriously difficult in a real fight. But the shoulder acts as the center of rotation for the arm, so when an opponent is swinging wildly, their wrist is traveling at least 5X faster than their elbow, which is less than half as far from the shoulder. The solution then is to focus on grabbing the arm near the elbow, then sliding down the forearm to grab the wrist.</p>
                <p><em>Jamming</em> - Blocking circular strikes such as haymakers, spinning kicks and baseball bats is difficult because of the inertia they carry. Evading them is also difficult because they're likely to be moving towards or chasing us. Jamming them however, has a higher success rate because it takes advantage of the relationship of speed vs distance from the center of rotation. Blocking such attacks at the opponent's knee or elbow means we absorb 5 times less inertia than at outer edge of the circle. Closing the gap like this also puts us within reach of their body, giving us the opportunity to counter attack at the same time.</p>
            </div>
        </div>
    ),
    "cross-extensor-reflex": (
        <div>
            <h2>Cross Extensor Reflex</h2>
            <p>This reflex causes the limb on one side of the body to react in response to pain experienced in the limb on the other side of the body. The goal of the reaction is to compensate for loss of support by the other limb.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Opposite Reactions</em> - If the flexor muscle of one limb contracts in response to pain, the extensor muscle of the limb on the other side of the body will contract. For example, stepping on a nail with the right foot will cause the hamstrings and hip flexors on the right side to pull the right foot up and away from the nail. At the same time the quadriceps and hip extensors on the left side will contract to support the full weight of the body</p>
                <p><em>Arms Too</em> - The reflex applies to the arms as well. For example, hyperextending the right elbow will cause biceps and the chest muscle on the right side to contract, bending the right elbow and pulling the arm "away from" the pain. The opposite will happen on the left side of the body; the left triceps and posterior muscles of the shoulder will contract, swinging the left arm out and away from the body.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Weapon Control</em> - Causing pain in the limb on one side of the body provides a sort of "remote control" over the opposite limb. To put distance between yourself and a knife held by an opponent's right hand for instance, attack their left arm by hyperextending the elbow, applying a finger extension lock, or simply yank the left wrist forcefully towards the ground.</p>
                <p><em>Stopping a Kick</em> - A front kick or round kick thrown by the opponent's right leg can be addressed by counter-kicking the front of their left ankle. Their reaction to the threat of falling forward due to the ankle kick causes the quadriceps of their supporting leg and the hamstrings of their kicking leg to contract, aborting the kick.</p>
            </div>
        </div>
    ),
    "fight-flight-response":  (
        <div>
            <h2>Fight-Flight Response</h2>
            <p>This group of autonomic reflexes are a response to stresses representing potential threats to survival. It causes multiple biochemical changes including the release of hormones and stored energy reserves in preparation to either fight or flee.</p>
            <h3>Key Points</h3>
            <div>
                <p><em>Fight vs Flee</em> - Some animals are initially more likely to flee while others are more likely to fight. Humans vary depending on the nature of the threat and individual predisposition. All animals can flip-flop from one to the other as the threat situation continues to unfold.</p>
                <p><em>Strength, Endurance &amp; Pain Tolerance</em> - These are greatly enhanced by the flood of biochemicals released as part of this response.</p>
                <p><em>Physical &amp; Mental Coordination</em> - These are diminished by the same hormones that increase strength and endurance. Especially hard-hit are fine-motor skills such as manual dexterity and cognitive functions such as verbal skills and decision-making.</p>
                <p><em>Perception</em> - Peripheral vision is reduced and foveal vision (center of visual field) is emphasized. Hearing becomes selective, filtering out aspects of the audible environment deemed irrelevant. Perception is then skewed to improve the chance of personal survival,  often at the expense of others.</p>
                <p><em>Memory</em> - Our ability to create short-term memory is reduced and the perceptual changes caused by stress hormones makes long-term memory less accurate.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Cognitive Training</em> - Drills should incorporate controlled amounts of stress to learn to manage the reflex reactions that accompany high-stress situations. Individuals predisposed to fighting must learn to control aggressive tendencies, while those who are not must learn to explicitly marshal aggressiveness when needed.</p>
                <p><em>Trigger the Flight Response</em> - Some threats are more likely than others to cause a fight response. Tactics should be selected which improve the chances of triggering a flight rather than a fight response in an opponent. The goal is to cause them to hesitate to better support deescalation and escape, while also improving success should a fight be unavoidable.</p>
                <p><em>Fine vs Gross Motor Skills</em> - The ability to maintain fine-motor skills under stress takes time to develop. Military self-defense programs focus on gross-motor skills because training time is limited to weeks rather than years. To maximize success, techniques should take into account the potential loss of fine-motor skills. Okinawan kempo for example grapples using the forearms and the crook of the elbow more than the hands for exactly this reason.</p>
                <p><em>Fitness vs Technique</em> - Self-defense situations are almost always short duration, so the fight-flight response will provide the strength and endurance needed. Martial training programs should incorporate elements that maintain baseline fitness, but should develop muscle memory in correct technique and train to maintain cognitive skills while under stress. Emphasizing competition-ready levels of fitness may be good for marketing, but it's not strictly required for self-defense.</p>
            </div>
        </div>
    ),
    "flinch-reflex":  (
        <div>
            <h2>Flinch Reflex</h2>
            <p>This is a spinal reflex intended to avoid injury to critical parts of the body.</p>
            <h3>Key Points</h3>
            <div>
                <p><em>Success Rate</em> - Some areas of the body which many self-defense curriculums use as attack targets are protected by fast flinch reflexes, making them more difficult to attack than commonly realized. Examples include the eyes and groin. Attacks against the eyes and groin have a low probability of success, so focus on other areas that are as effective or better.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Open the Fingers</em> - The flinch reflex for the eyes causes the fingers to extend in preparation for protecting the face. By intentionally triggering this reflex, we can cause the opponent loosen their grip enough to employ other followups to release their grip.</p>
            </div>
        </div>
    ),
    "freefall":  (
        <div>
            <h2>Freefall</h2>
            <p>The heavier opponent generally has an advantage in a fight. In self-defense it's very common to be outweighed by an attacker, but we can temporarily offset this disadvantage by leveraging gravity or rather freefall to momentarily increase our bodyweight.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Freefall</em> - When an object falls, it accelerates rapidly. An object starting from a standstill will be traveling at 9.8 meters/second after one second of freefall, and 19.6 meters/second after just two seconds. Each additional second of freefall will double it's speed from the previous second.</p>
                <p><em>Weight</em> - Bodyweight (in pounds) is bodymass (measured perhaps, in cheesecake eaten) plus the tug of gravity. So a person weighing 180 lbs jumping up and landing on a scale after a freefall of just one second will register 360 lbs.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Sacrifice Throws</em> - Grapplers have long used freefall as tactic to throw opponents. Judo techniques such as Yokowakari do exactly this.</p>
                <p><em>Sit on Your Punch</em> - A boxer's metaphor for the correct technique for punches like the cross. Power is increased not by leaning towards the opponent, but by rapidly bending the knees to drop the bodyweight straight down while adopting a stance that creates a stable base, coupling the increased bodyweight to the punch.</p>
                <p><em>Opening the Grip</em> - JiuJitsu has long used freefall to facilitate grip escapes. An example is to use an opponent's grip to pull them in, coupling them to your bodyweight before sharply bending your knees or stomping your feet to transfer your increased weight to their low back as part of a strategy to trigger their balance reflex into opening their fingers.</p>
            </div>
        </div>
    ),
    "inertia": (
        <div>
            <h2>Inertia</h2>
            <p>Inertia is the tendency of a moving object to continue moving, and of a stationary object to remain stationary. Heavier objects are more difficult to get moving from a standstill. Once moving, they're also more difficult to stop.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Mass increases Inertia</em> - Objects with more mass have more inertia. A baseball bat swung at 70 mph carries far more inertia than a baseball thrown at 90 mph.</p>
                <p><em>Inertia increases Impact</em> - The more inertia a moving object has, the more energy it delivers on impact.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Punch Alternatives</em> - A boxer's punch can generate a lot of inertia, but this skill requires years of training and it's very common for the inertia to break the boxer's own wrist or hand when bare-knuckled. Forearms, elbows, hammerfists and open palm slaps can all generate as much inertia but with less skill and without the risk of self-injury.</p>
                <p><em>Kick Alternatives</em> - Some foot kicks carry more inertia than others, but all require skill to master and carry the risk of losing your balance or having your leg trapped by an opponent. Thigh, knee and shin kicks all carry at least as much inertia but require less skill to master and carry less risk because they are delivered well below waist height.</p>
                <p><em>Center of Mass</em> - The center of mass of a human sits inside the pelvis, just below the navel. Standup judo technique uses the judoka's center (hips and buttocks) to generate inertia during movement to offset the advantages a stronger and heavier opponent. Ground grapplers generate inertia for stability by keeping their hips and buttocks low to the ground to thwart reversals by their opponent.</p>
            </div>
        </div>
    ),
    "leverage": (
        <div>
            <h2>Leverage</h2>
            <p>A lever is a simple machine used to move things that would otherwise be too heavy.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Lever Arm</em> - This is a rigid bar which, when combined with a pivot point, does the work of lifting or prying. One end of the arm is placed under or next to the object to be moved, and the other end is either pulled or pushed to accomplish the move.</p>
                <p><em>Fulcrum</em> - Also called the pivot point, this is the point around which the arm pivots, or rotates. Unlike a see-saw, the pivot point is not placed at the mid-point of the arm. Instead it is normally placed closer to the object to be moved, dividing the arm into two unequal lengths.</p>
                <p><em>Mechanical Advantage</em> - The amount of mechanical advantage you create with a lever depends on the lengths of the arm on either side of the fulcurm relative to each other. The longer the end against which you pull or push relative to the one that moves the object, the greater the mechanical advantage.  In other words, the greater the mechanical advantage, the less effort you need to apply.</p>
                <p><em>Increased Load</em> - Placing the fulcrum close to the load lets you move a heavy object a short distance with little effort. A pry bar is one common application of this principle.</p>
                <p><em>Increased Speed</em> - Placing the fulcrum close to the force allows you to move an object more quickly, and make it travel a longer distance. A trebuchet was an ancient weapon of war that applied this principle.</p>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Speed not Strength</em> - The joints in the human skeleton incorporate leverage in a way that moves the joint quickly, rather than forcefully. By applying mechanical advantage against a joint, we can overcome the relatively weak force it produces.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Grip Releases</em> - The long bones of the forearm can be used as lever arms with the opponent's grip acting as as the pivot point. This allows us to break the grip of even a much stronger opponent.</p>
                <p><em>Joint Locks</em> - Many joint locks use the long bones of the opponent and our own body as lever arms. In some cases the leverage is used to apply force to one of the opponent's joints, and in others the joint itself is used as the fulcrum.</p>
                <p><em>Disarms</em> - Similar to breaking a wrist grip, we can use the weapon held by an opponent as a lever against their own grip to achieve a disarm.</p>
            </div>
        </div>
    ),
    "motor-skills":  (
        <div>
            <h2>Motor Skills</h2>
            <p><em>Motor Skills</em> are learned patterns of movement which achieve a specific goal in a repeatable way. They are the building blocks of complex procedural memory, such as the ability to out-dribble a basketball opponent.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Gross Motor Skills</em> - Examples are balancing, walking and crawling. These use large muscle groups which have a lower density of nerve innervation per muscle spindle, such as the muscles of the triceps, chest, back, abdomen, rump and legs. Most gross motor skills are learned in early childhood. There's two subgroups:</p>
                <ul>
                    <li><em>Loco-Motor Skills</em> - Examples are running, jumping and swimming</li>
                    <li><em>Object-Control Skills</em> - Examples are throwing, catching and ball-kicking</li>
                </ul>
                <p><em>Fine Motor Skills</em> - Examples include writing, musical instrument playing, or twirling a balisong. These involve muscle groups having a high density of nerve innervation such as the muscles which control the hand, wrist, ankle and toes.</p>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Stages of Learning</em> - These skills are learned in three stages; <em>cognitive</em>, <em>associative</em> and <em>autonomous</em>.</p>
                <p><em>Rate of Learning</em> - Different parts of the body acquire skills at different rates:</p>
                <ul>
                    <li>Movements of the head and face develop faster than those of the arms and hands. Movements of the arms and hands develop faster than those of the legs and feet.</li>
                    <li>Movements of muscles closer to the axial skeleton develop faster than those farther away. The back, shoulder and chest muscles learn faster than the wrists and hands for instance.</li>
                    <li>Gross motor skills develop faster than fine motor skills.</li>
                </ul>
                <p><em>Success Rate</em> - A number of factors influence the success rate when applying motor skills:</p>
                <ul>
                    <li><em>Stress</em> - The effect of stress on the ability to perform is a bell-shaped curve. Initially stress improves success rate, but beyond a certain peak stress-performance point, success in applying motor skills declines.</li>
                    <li><em>Motivation</em> - Success rate is improved by the individual's having "self-interest" in achieving the goal.</li>
                    <li><em>Fatigue</em> - Success rate improves with repetition, but then declines again when repetitive fatigue negatively influences motivation</li>
                    <li><em>Gender</em> - Females tend to develop fine motor skills faster than males and have higher success in real world situations.</li>
                </ul>
                <p><em>Heart Rate</em> - The ability to apply motor skills changes with heart rate as measured in beats per minute:</p>
                <ul>
                    <li><em>60-115 bpm</em> - This is the optimal range for execution of fine motor skills.</li>
                    <li><em>115-145 bpm</em> - This is the optimal range for complex  combat/survival skills that do not rely extensively on fine motor skills.</li>
                    <li><em>145-175 bpm</em> - The success rate of fine motor skills is near zero.</li>
                    <li><em>Above 175 bpm</em> - In this range irrational behavior, freezing and voiding of bladder/bowels is likely. But it also delivers the highest levels of strength and speed when executing skills such as running or charging.</li>
                </ul>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Drill Design</em> - By understanding the stages of learning, rate of learning and success rate factors on learning and application, drills can be enhanced to reduce the learning curve and maximize the success rate in real world scenarios.</p>
                <p><em>Technique Design</em> - Techniques should be designed with the expectation that fine motor skills such as the ability to grasp with the fingers deteriorates under stress. For real-world success, grasping with hook-grip (monkey grip) or the crease of the bent elbow should be practiced preferntially over grabbing with the hand.</p>
            </div>
        </div>
    ),
    "myotatic-reflex":  (
        <div>
            <h2>Myotatic Reflex</h2>
            <p>Another spinal reflex, there's two types; one is triggered by tendons and the other by muscles.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Deep Tendon Reflex</em> - Commonly called the "knee jerk reaction" or "patellar tendon reflex", this reflex causes your leg to jump when the tendon below the knee-cap is tapped with a small rubber mallet. It's a defensive mechanism intended to prevent the tendon from stretch-related injuries. When your body detects the tendon is being stretched rapidly, it attempts to shorten the tendon by straightening the knee joint. This is accomplished by briefly contracting the muscles of the thigh, while at the same time relaxing the opposing hamstring muscles. Specialized nerves embedded in the tendon, called Golgi Tendon Organs detect the stretch of the tendon and fire a built-in pattern of muscle movement to prevent injury.</p>
                <p><em>Muscle Stretch Reflex</em> - Like the deep tendon reflex, this reflex protects muscles from stretch-related injuries. Also called the myotatic response, it is triggered by specialized nerves in the muscle called muscle spindles. Unlike the tendon stretch reflex, this one operates in two ways:</p>
                <ul>
                    <li><em>Reflex Contraction</em> - Like the tendon stretch reflex, a muscle will contract when rapidly and forcefully stretched. For instance, grabbing and yanking on the wrist to straighten the elbow will cause the bicep muscle to contract to avoid injury to itself and to the elbow joint.</li>
                    <li><em>Muscle Inhibition</em> - When a muscle is placed in a <em>sustained</em> stretched position, this reflex <em>prevents</em> you from contracting it to avoid tearing muscle tissue.</li>
                </ul>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Predictable Reaction</em> - The muscle &amp; tendon reflexes are spinal reflexes, which makes them very difficult to consiously override.</p>
                <p><em>Every Muscle has Them</em> - Every muscle and tendon in the human body has these built-in reflexes.</p>
                <p><em>Muscle Contraction</em> - The deep tendon and and muscle stretch reflex can both be used to trigger a muscle to contract.</p>
                <p><em>Muscle Inhibition</em> - The muscle stretch reflex can also be used to prevent a muscle from contracting.</p>
            </div>
            <h3>Non-Martial Uses</h3>
            <div>
                <p><em>Plyometric Exercises</em> - These exercises overload muscles, often using gravity, to trigger the stretch reflex. One example is jumping on and off a bench, which uses gravity to increase your bodyweight, with the goal of recruiting more muscle fibers than would normally be involved during the rebound phase of the jump.</p>
                <p><em>Isometric Stretching</em> - These exercises improve flexibility over static stretching alone by placing a muscle on a gentle, but sustained stretch, coupled with brief attempts at forcefully contracting the same muscle.</p>
                <p><em>Myofascial Release</em> - This is a massage technique that uses elbows or forearms to place a sustained stretch the skin, thus stretching underlying muscle via the myofsacial layer, with the goal of releasing muscle spasms and relaxing hypertonic muscles.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Facilitate Escapes</em> - We can use these reflexes to either flex or extend a joint to help release an opponent's grip or hold. When combined with other tactics this additional tool can greatly increase the self-defense success rate.</p>
                <p><em>Improve Counter-Attacks</em>  - Since these reflexes not only contract muscles but also inhibit opposing muscles, the reaction in the opponent happens very rapidly and forcefully. We can take advantage of this to improve counter attack effectiveness should it be necessary to injure an opponent by creating techniques that trigger reflexes while applying leverage and bodyweight.</p>
            </div>
        </div>
    ),
    "nervous-system":  (
        <div>
            <h2>Nervous System</h2>
            <p>The nervous system is how the brain communicates with the rest of the body.</p>
            <h3>Concepts: Building Blocks</h3>
            <div>
                <p><em>Nerves</em> - Nerves carry signals which control bodily functions, such as the movement of the skeletal system. They are the &lsquo;wires&rsquo; of the body.</p>
                <p><em>Nerve Impulses</em> - Nerve signals are called impulses.</p>
                <p><em>Firing</em> - A nerve is said to be firing when an impulse is traveling along the it.</p>
                <p><em>Nerve Conduction Velocity</em> - Nerve impulses do not travel instantly from one end to the other, but rather move at a known speed.</p>
                <p><em>Motor Nerves</em> - These nerves carry signals from the brain to muscles. They cause muscles to contract. Also called "efferent nerves".</p>
                <p><em>Sensory Nerves</em> - These nerves send signals &lsquo;upstream&rsquo; to the brain. Also called "afferent nerves"</p>
            </div>
            <h3>Concepts: Subsystems</h3>
            <div>
                <p>The Nervous System is sub-divided into:</p>
                <ul>
                    <li><em>Central</em> - The brain and spinal cord.</li>
                    <li><em>Peripheral</em> - The part of the nerves from where they exit the spinal cord.</li>
                </ul>
                <p>The Peripheral Nervous System is also sub-divided:</p>
                <ul>
                    <li><em>Somatic</em> - Made up of the nerves that supply the skeletal muscles.</li>
                    <li><em>Autonomic</em> - Made up of the nerves that supply the organs.</li>
                </ul>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Artificial Triggering</em> - Nerves can be fired by pressing or striking them, so the systems they control can be manipulated in a fight to our advantage.</p>
                <p><em>Bypass Concious Control</em> - We're consciously aware of some sensory nerve input, but not others.</p>
                <ul>
                    <li>Conscious sensations are things like heat or pain.</li>
                    <li>Non-Conscious sensations include tendon stretch.</li>
                </ul>
            </div>
        </div>
    ),
    "proprioception":  (
        <div>
            <h2>Proprioception</h2>
            <p>Motor and sensory nerves collaborate in every movement we make, in a process is called proprioception. It enables us to determine whether we are in balance or falling, and whether say, a punch, has landed or missed its target.</p>
            <h3>Key Points</h3>
            <div>
                <p><em>Closed-Loop Feedback</em> - Proprioception is very similar to an engineering concept called closed-loop feedback, which is used in the designs of many items, from household thermostats to robotic servo-motors.</p>
                <p><em>Overshoot</em> - Engineered closed-loop systems have a tendency to overshoot the desired stopping point. This tendency is called hysteresis. Surprisingly, proprioception has the same shortcoming of artificial closed-loop systems in that it tends to overshoot the desired stopping point.</p>
                <p><em>Window of Opportunity</em> - When our proprioceptive mechanisms overshoot there is a small delay in our ability to react to any additional stimulus.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Slipping the Punch</em> - Boxers will use small movements of the head and upper back to cause the opponent&rsquo;s punch to miss by mere inches. This causes the opponent's fist to travel past the intenteded target, taking their balance, and leading to a small delay which allows the other figher to close the gap and deliver punches to the body and head with little resistance.</p>
                <p><em>Kazushi: Push-Pull</em> - A foundational principle in judo is to push or pull the opponent with the goal of triggering them to react in the opposite direction. So a short shove, for instance, is intended to cause the opponent to push back, at which point the originator of the shove continues the push back by pulling the opponent forward into a throw.</p>
                <p><em>Blending</em> - In battlefield jiujitsu, armor and heavy weapons resulted in momentum which aikijitsu (and later aikido) uses to lead an attacker into a counter-attack.</p>
            </div>
        </div>
    ),
    "reflex-physiology":  (
        <div>
            <h2>Reflex Physiology</h2>
            <p>Reflexes are reactions which happen largely without our need to think. We are born with some reflexes, but others are learned. Innate reflexes are effectively hard-wired functions of the nervous system.</p>
            <h3>Concepts: Innate Reflexes</h3>
            <div>
                <p><em>Spinal Reflexes</em> - These are controlled directly in the spinal cord. Examples are pulling our hand off a hot stove or swinging our arms when we lose our balance. We know these reflexes are not initiated in the brain because they happen too quickly based on the speed at which nerve impulses travel. In other words, there isn't enough time for the sensory impulses to travel to the brain and send a motor impulse back to muscles. Examples include the <b>flinch reflex</b>, <b>balance reflex</b> and <b>knee-jerk reflex</b>.</p>
                <p><em>Sympathetic Reflexes</em> - These reflexes affect the blood pressure, breating rate and function of internal organs. They are controlled by the Sympathetic Nervous System, which is the part of the Autonomic Nervous System that reacts to potential threats. Examples include the <b>fight or flight reflex</b> and <b>vasovagal reflex</b>.</p>
            </div>
            <h3>Concepts: Learned Reflexes</h3>
            <div>
                <p><em>Procedural Memory</em> - Learned reflexes are the result of repetitive actions in response to some stimulation. Most are complex reactions, initiated by a combination of conscious and subconscious mechanisms. Some involve movements of the skeletal system, while others are verbal or emotional responses to visual or auditory stimulus.</p>
                <p><em>Muscle Memory</em> - A type of procedural memory which involves movements of the skeletal system. In contrast with spinal reflexes which are often jerky and ungraceful, muscle memory reflexes are complex, coordinated and targeted. These reflexes are usually learned behavior layered on top of builtin reflexes. Examples include walking, running and throwing.</p>
                <p><em>Contextual Memory</em> - Our memory incorporates context along with the actual thing remembered. So procedural memory incorporates the context in which the procedure was practiced, which makes it somewhat less reliable than builtin reflexes if the procedure is not reinforced in different contexts. For instance, we can develop procedural memory to override the flinch reflex, but the builtin reflex will usually take priority when you are taken unprepared.</p>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Artificial Firing</em> - Nerves have electromechanical properties. Striking or pressing it will fire it, which means we can leverage them in a fight.</p>
                <p><em>Involuntary Reactions</em> - Reflexes are difficult to override, so if we develop techniques that trigger them in an opponent to our advantage, we give ourselves an edge against a stronger or faster opponent.</p>
                <p><em>Predictable Reactions</em> - Reflex responses are predictable. Offensive techniques which trigger them in an oppoent give us an edge because we know what they're going to do before they do it. Defensive techniques which trigger them in ouselves reduces our learning curve and increases our  success rate while under stress.</p>
            </div>
            <h3>Martial Uses: Spinal Reflexes</h3>
            <div>
                <p>Both builtin and learned reflexes can be leveraged for martial purposes.</p>
                <p><em>Extremely Fast</em> - They are extremely fast, so developing muscle memory in fighting techniques which take advangage of our own builtin reflexes will improve our success against faster opponents as well as unanticipated attacks.</p>
                <p><em>Predictable Reactions</em> - They result in predictable reactions and our ability to consiously override them is limited. Triggering them in an opponent can give us a window of opportunity against a stronger or faster combatant.</p>
            </div>
            <h3>Martial Uses: Procedural Memory</h3>
            <div>
                <p><em>Adoption Rate</em> - Combative skills have the highest success rate if they incorporate builtin reflexes.</p>
                <p><em>Drill Effectiveness</em> - The learning curve will be reduced if drills use the knowledge of how contextual memory reinforces procedural memory. In addition to varying the practice location and the clothing worn, drills should incorporate a controlled degree of emotional stress to reinforce the association betwen the stimulus and the reaction.</p>
            </div>
        </div>
    ),
    "reflex-syncope":  (
        <div>
            <h2>Reflex Syncope</h2>
            <p>Sometimes just referred to as syncope, this reflex of the autonomic nervous system results in dizziness, queasiness, disorientation and possibly unconsciousness. There are several categories of this reflex, based on the triggering mechanism.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Vasovagal Syncope</em> - This category can be triggered by fear and other emotional stresses.</p>
                <p><em>Carotid Sinus Syncope</em> - This results from physical stimulation of blood pressure control structures of the carotid artery.</p>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Lowered Heart Rate &amp; Blood Pressure</em> - Usually accompanies carotid sinus syncope.</p>
                <p><em>Elevated Heart Rate, Blood Presure &amp; Breathing</em> - Usually associated with vasovagal syncope.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Carotid Sinus Syncope</em> - These structures are readily accessible in a fight and can be leveraged through striking to trigger syncope. The associated drop in blood pressure can be drastic, resulting in a standing TKO or temporary unconsciousness.</p>
                <p><em>Vasovagal Syncope</em> - Autonomic reflexes are essentially built-in alarms. When one has been triggered the threshold for triggering others can be lower than would otherwise be needed. The stress response to a confrontation alone can increase the success rate of attacks to say, the carotid sinus, but we may be able further to improve our odds by intentionally raising the opponent's stress level for instance, by coordinated use of both physical and verbal attacks.</p>
            </div>
        </div>
    ),
    "musculo-skeletal-system":  (
        <div>
            <h2>Musculo-Skeletal System</h2>
            <p>The musculoskeletal system consists of muscles, bones, ligaments and tendons.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Bones</em> - Bones act as levers during the movement of joints.</p>
                <p><em>Muscles</em> - Muscles move joints by pulling on tendons (like a string puppet).</p>
                <p><em>Tendons</em> - Tendons are tough, elastic cords which connect muscles to bones.</p>
                <p><em>Ligaments</em> - Ligaments are non-elastic straps which hold bones together to make a joint.</p>
            </div>
            <h3>Key Points</h3>
            <div>
                <p><em>Straight Lines</em> - A muscle can only act along a straight line.</p>
                <p><em>Speed over Strength</em> - The leverage incorporated into most joints is adapted to favor speed over strength.</p>
                <p><em>Range of Motion</em> - A muscle only exert maximum force when the joint is in the middle of it&rsquo;s range of motion.</p>
                <p><em>Elastic Limit</em> - Tendons are more elastic than ligaments but both will suffer injury when stretched beyond their elastic limit. Both tendons and ligaments resist forces along their length, but are vulnerable to sideways and twisting forces, called shearing forces.</p>
                <p><em>Golgi Tendon Organs</em> - Tendons have sensory nerves embedded in them which monitor the amount of stretch the tendon is under. These sensors play a key role in musculoskeletal reflexes.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Anatomical Landmarks</em> - Bones act as anatomical landmarks which allow us to locate specific structures, even through heavy winter clothing.</p>
                <p><em>Overcome Strength</em> - Exploit the adaptation of joints for speed  over strength by applying leverage. Bypass muscular strength by applying torque against joints.</p>
                <p><em>Grappling 2.0</em> - Maximize the effect of leverage and torque by applying while the joint is at the end of it's range of motion. Refine grappling techniqes to incorporate shearing forces when attacking a joint.</p>
            </div>
        </div>
    ),
    "triangles":  (
        <div>
            <h2>Triangles</h2>
            <p>Fighting techniques from every region of the world incorporate angles, especially in footwork. Triangles in particular have attributes which, when applied to fighting, can significantly improve success rates.</p>
            <h3>Concepts</h3>
            <div>
                <p><em>Foundations</em> - Triangles have three sides, creating three angles on the inside of the figure. If you add up the values for these three angles the total is 180 degrees, or half the number of degrees in a circle.</p>
                <p><em>Right Triangle</em> - In this setup, one of the three angles is 90 degrees, and there's a special mathmatical relationship between the sides connected to the right angle and the one that is not.</p>
                <p><em>Equilateral Triangle</em> - In this configuration, the lengths of each of the three sides are equal. So are the angles; Each is 60 degrees.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Balance Point</em> - To control an opponent's balance you must prevent them from compensating by moving one or both of their feet. The balance point is a point on the floor; When the opponent is tugged towards it, their weight shifts in a way that makes it difficult to take a compensating step.</p>
                <p>The balance point is the third point of a triangle; The other two points are the opponent's feet. It's an equilateral triangle, so the location of the balance point is roughly the same distance from the opponent as the distance between their feet. So the wider their stance, the farther the balance point is from them.</p>
                <p><em>Deflection Point</em> - Hands move quickly in a fight, so deflecting an opponent's hand by targeting their wrist has a low success rate. But even if you manage to make contact with their wrist, deflecting their hand by say, 12 inches, requires you to move your hand fully 12 inches, crossing your own midline. If you miss their wrist, not only do you get hit or cut, but you're also left overextended and exposed.</p>
                <p>But if you target the elbow, your success rate will improve because it moves less quickly. It's also closer to your own hand if you're roughly within arm's reach of their head. But the big benefit comes when you realize your forearm and their foream create two sides of an equilateral triangle. So targeting their elbow deflects their hand by the length of their forearm, without having to cross your own midline.</p>
            </div>
        </div>
    ),
    "vision-system":  (
        <div>
            <h2>Vision System</h2>
            <h3>Concepts &amp; Key Points</h3>
            <div>
                <p><em>Foveation</em> - Our eyes see detail very well directly in the center of our field of view, but not in the periphery.</p>
                <p><em>Motion Sensitivity</em> - We detect and react to motion  in our peripheral vision better than in the center of our field of view, and we have a tracking reflex that causes us to turn our head towards motion detected in our peripheral vision. This is why we react more quickly while driving, to to a car swerving into our lane from the left or right of us than we do to the car in front of us abruptly slowing down.</p>
            </div>
            <h3>Martial Uses</h3>
            <div>
                <p><em>Focus on the Heart</em> - While fighting, maintain your gaze in the center of the opponent&rsquo;s chest, and use your peripheral vision to pick up on the motions of their hands and feet.</p>
            </div>
        </div>
    ),
    "conflict-management":  (
        <div>
            <h2>Conflict Management</h2>
            <p>Coming soon...</p>
        </div>
    ),
    "pattern-interrupts":  (
        <div>
            <h2>Pattern Interrupts</h2>
            <p>Coming soon...</p>
        </div>
    ),
    "problem-solving":  (
        <div>
            <h2>Problem Solving</h2>
            <p>Coming soon...</p>
        </div>
    )
};

export default class MartialScienceDoc extends React.Component {

    render() {
        let match = matchPath(this.props.location.pathname, { path: "/learn/martial-science/:topicId"} );
        if(match === null) {
            return (
                <div id="martial-science">
                    <BreadcrumbSection>
                        <RoutedHashLink to={"/learn#academic-topics"}>Academic Topics</RoutedHashLink>
                        <div>
                            <h2>Martial Science</h2>
                            {martialScienceTopics["index"]}
                        </div>
                    </BreadcrumbSection>
                    <BackLink to="/learn" />
                </div>
            );
        } else {
            return (
                <div id="martial-science">
                    <BreadcrumbSection>
                        <ContentLink to="/learn/martial-science">Martial Science</ContentLink>
                        {
                            (match.params.topicId === "problem-solving")
                                ? <OutsideTheBox />
                                : martialScienceTopics[match.params.topicId]
                        }
                    </BreadcrumbSection>
                    <BackLink to="/learn/martial-science" />
                </div>
            );
        }
    }

}

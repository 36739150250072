import Logger from '../../Helper/Logger.js'
import AppConfig from "../../AppConfig"
import DomHelper from '../../Helper/DomHelper.js';

class _StitchSession {
    
    _logger = new Logger("StitchSession");
    _config = AppConfig.config("stitch");
    _session = null;

    _initSession(config) {
        return new Promise( (resolve, reject) => {
            let stitchApi = window["stitch"];

            let credential = new stitchApi.UserApiKeyCredential(config.apiKey);
            let client = stitchApi.Stitch.initializeDefaultAppClient(config.appId);
            let userPromise = client.auth.loginWithCredential(credential)
            .then( authedId => {
                /*nop*/ 
            })
            .catch( err => { 
                reject(err);
            });

            let session = { client: client, user: userPromise };
            session.user.then( () => {
                resolve(session);
            });
        });
    }

    _waitForStitch(completeFunc) {
        if("stitch" in window) {
            completeFunc();
        } else {
            setTimeout(() => { this._waitForStitch(completeFunc); }, 100);
        }
    }

    initialize() {
        return new Promise( (resolve, reject) => {
            if(this._session != null) {
                resolve(this._session);
            } else {
                let config = AppConfig.config("stitch");
                DomHelper.injectScript(config.apiUrl);
                this._waitForStitch( () => { 
                    this._initSession(config)
                    .then( session => {
                        this._session = session;
                        resolve(session);
                    }).catch( err => {
                        this._logger.error("initStitch()", err);
                        reject(err);
                    });
                });
            }
        });
    }

    _waitForSession(completeFunc) {
        if(this._session != null) {
            completeFunc(this._session);
        } else {
            setTimeout(() => { this._waitForSession(completeFunc); }, 100);
        }
    }

    instance() {
        return new Promise( (resolve, reject) => {
            this._waitForSession( session => { 
                resolve(session.client); 
            });
        });
    }
};

var StitchSession = new _StitchSession();
export default StitchSession;

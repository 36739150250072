import React from 'react';
import {HashLink, ExternLink, TopLink} from "../View/Links";

const content = (
  <div id="top">
    <h2>Outside-the-Box</h2>
    <p>The science behind problem-solving, insight, and creativity. Based on the research of <ExternLink to="https://www.amazon.com/s/ref=nb_sb_noss?field-keywords=edward+de+bono">Edward De Bono, MD, PhD</ExternLink>.</p>
    <ol>
      <li><HashLink to="#diving-in">Diving In</HashLink></li>
      <li><HashLink to="#types-of-thinking">Types of Thinking</HashLink></li>
      <li><HashLink to="#thinking-in-a-group">Thinking in a Group</HashLink></li>
      <li><HashLink to="#six-thinking-hats">Six Thinking Hats</HashLink></li>
      <li><HashLink to="#what-is-creativity">What is Creativity?</HashLink></li>
      <li><HashLink to="#creativity-step-by-step">Creativity Step-by-Step</HashLink></li>
      <li><HashLink to="#best-practices">Best Practices</HashLink></li>
      <li><HashLink to="#power-of-perception">Power of Perception</HashLink></li>
      <li><HashLink to="#case-studies">Case Studies</HashLink></li>
    </ol>
    <div id="diving-in">
      <h3>1. Diving In</h3>
      <p><dfn>Thinking</dfn> is the ability to reason, compare, search for alternatives, create, evaluate, and make decisions. It is the skill needed to solve problems. For most people, solving problems is an inefficient process because it relies on insight. <dfn>Cognition research</dfn> or the study of how people think has produced a wealth of useful tools that can improve the efficiency of problem solving.</p>
      <p>Let's look at an example of insight in action:</p>
      <ol>
        <li>Take a look at these two shapes:
            <div className="indent" style={{marginTop: "0.4em"}}><img style={{width: "5em"}} alt="" src="/outside-the-box/two-shapes.png" /></div>
          <p>What do they have in common? What are their differences?</p>
          <ul>
            <li>They're both blue.</li>
            <li>One is square, the other is round.</li>
          </ul>
        </li>
        <li>But if we generalize the differences, a new similarity pops out:
          <ul>
            <li>Both circles and squares are simple geometric shapes.</li>
          </ul>
        </li>
        <li>Now lets add a third shape:
          <div className="indent  " style={{marginTop: "0.4em"}}><img style={{width: "8.7em"}} alt="" src="/outside-the-box/three-shapes.png" /></div>
        </li>
        <li>What are their similarities and differences?:
          <ul>
            <li>They are all simple geometric shapes.</li>
            <li>Two are blue and one is red.</li>
          </ul>
        </li>
        <li>Now if we generalize their differences again, a new <em>similiarity</em> pops out:
          <ul>
            <li>Both red and blue are primary colors.</li>
          </ul>
        </li>
      </ol>
      <p>Listing the similarities and differences is called <dfn>comparative analysis</dfn> and most people do it intuitively. Generalizing to find additional relationships is intuitive to some, but not to others. So, what insights can we gain from this?</p>
      <ul>
        <li>Once we found the shape similarity by generalizing about the first picture, that same similiarity was immediately obvious when looking at the second picture.</li>
        <li>We often see differences first, but finding similiarities often takes more digging.</li>
      </ul>
      <p>To summarize: By following a step-by-step "recipe", we can force insight to occur, rather than waiting for it to 'hit' us. With practice, this recipe will become intuitive. In other words, we can <em>learn</em> to be insightful.</p>
      <TopLink />
    </div>
    <div id="types-of-thinking">
      <h3>2. Types of Thinking</h3>
      <p>Research shows people use multiple types of thinking when approaching a problem. You're probably already familiar with a few of them:</p>
      <ul>
        <li><em>Vertical</em> - This is a goal-oriented thinking skill that discards all ideas not immediately relevant to a goal. Vertical thinking is usually learned early in life as a side-effect of taking tests at school which have only one correct answer.</li>
        <li><em>Critical</em> - This type of thinking identifies shortcomings in an idea. Anyone who has said; "That won't work!", in response to someone else's idea partially understands critical thinking.</li>
        <li><em>Creative</em> - This is the skill needed to come up with new ideas. It requires exploration of alternatives which don't seem immediately relevant to a goal. It also requires exploration of alternatives which may at first appear impractical.</li>
      </ul>
      <TopLink />
    </div>
    <div id="thinking-in-a-group">
      <h3>3. Thinking in a Group</h3>
      <p>People often collaborate in groups because it allows a problem to be evauluated from a broader variety of angles more quickly than working alone. In a well-run meeting a facilitator helps keep everyone looking at the problem from the same angle at the same time.</p>
      <p>This is called <dfn>parallel thinking</dfn>. In this process, the facilitator helps organize everyone to look at the problem using the same type of thinking at the same time. Durin parallel thinking it's not uncommon for the same solution(s) to 'jump out' at everyone almost simultaneously.</p>
      <p>If a facilitator is not present, it is rare for any two people in the meeting to be looking at the problem from the same angle at the same time. If you've ever sat through a meeting in which everyone was talking at once you can appreciate the need for parallel thinking. These types of meetings result from having a room full of strong critical and vertical thinkers, each intent on voicing their own ideas while at the same time shooting down the ideas of everyone else.</p>
      <p>Parallel thinking is most useful in a group, but you can also use it working alone to achieve some of the benefit that can normally be achieved only in a team.</p>
      <p>DeBono identified six different thinking skills, which he refers to as <dfn>hats</dfn>. The hats are grouped into three pairs of opposing perspectives:</p>
      <div className="indent table">
        <div><div>Factual</div><div>&nbsp;&lt;=&gt;&nbsp;</div><div>Emotional</div></div>
        <div><div>Critical</div><div>&nbsp;&lt;=&gt;&nbsp;</div><div>Optimistic</div></div>
        <div><div>Vertical</div><div>&nbsp;&lt;=&gt;&nbsp;</div><div>Lateral</div></div>
      </div>
      <p>To be effective, everyone in the group must 'switch hats' together, looking at the problem in the same way at the same time. Achieving this level of coordination is the most important, and also the most difficult aspect of parallel thinking to master.</p>
      <p>To prompt the group to look at the problem from a certain angle, or to inform everyone you're about to change the angle from which you're looking at the problem, it is common to hear statements like:</p>
      <ul>
        <li>Let's open this meeting by laying out what we know ... just the facts.</li>
        <li>Time's up, before we break I want to get everyone's gut reaction to what we discussed.</li>
        <li>On the surface this idea looks okay, but let me apply some critical thinking to it for a moment.</li>
        <li>I have my doubts about that idea, but under optimistic circumstances, what would be the benefits?</li>
        <li>Let me put on my vertical thinking hat for a minute ... these ideas are good, but we should table them for another meeting because we're running short on time.</li>
        <li>That idea looks promising; let's apply some lateral thinking to elaborate on it.</li>
      </ul>
      <p>Six Hats is a set of tools rather than a methodology. While suggestions are made regarding the best time to use each skill, it is not required to apply them in a particular sequence. As the session proceeds in fact, some tools may be re-applied. It's also not necessary to use all the tools in every working session; use the ones which are relevant.</p>
      <p>The most important point however, is that everyone must apply the same skill <em>at the same time</em>.</p>
      <TopLink />
    </div>
    <div id="six-thinking-hats">
      <h3>4. Six Thinking Hats</h3>
      <div id="factual-thinking">
        <h4>Factual thinking</h4>
        <p>This thinking skill enumerates facts and figures. It is usually applied towards the beginning of a problem solving session. Important questions to ask in factual thinking are:</p>
        <ul>
          <li>What information do we have?</li>
          <li>What information do we need?</li>
          <li>What questions do we need to ask?</li>
          <li>How are we going to get the information we need?</li>
        </ul>
        <p>The hard part of becoming a good factual thinker is separating <em>facts</em> from <em>interpretations</em> of facts. Here are some examples:</p>
        <ol>
          <li>"There is a rising trend in the number of women smoking cigars"
            <p>This statement may be a fact, or it may be an interpretation of data indicating a rise in the purchase of cigars by women. To call this a trend might be an assumption, depending on the time frame and the number of purchases used in the study.</p>
            <p>It also might be assumption that the women are smoking the cigars they are buying. If the cigars are being given to men as presents, then the data actually may reflect a rising trend of male cigar smokers.</p>
          </li>
          <li>"They don't like peanut butter in Mexico"
            <p>This statement was the obvious conclusion after a failed attempt to introduce Skippy peanut butter into Mexico by CPC International.</p>
            <p>Extensive (and expensive) market research uncovered the truth: They liked the peanut butter, but didn't know what to do with it ... bread is not a popular part of their diet.</p>
          </li>
        </ol>
      </div>
      <div id="emotional-thinking">
        <h4>Emotional thinking</h4>
        <p>This skill elicits the intuitive or "gut reactions" to an idea. People's intuitive reactions are based on past experience, and are often governed by a perception of how an issue will affect them; their job description, income, working hours, commute, position of authority, appearance of expertise, or other aspects of their lives.</p>
        <p>Intuition is like a trusted advisor; if it has been reliable in the past, you will be likely to trust it in the future. Since most real-world problems don't have a single 'correct' answer, most decisions are in fact based on intuition rather than just the merits of available options.</p>
        <p>Perception influences people's emotional response to an idea. Looking at the idea from several non-emotional angles prior to reaching a decision often changes perceptions and therefore the decision.</p>
        <p>Emotional thinking is often used both at the beginning and end of a working session:</p>
        <ul>
          <li>Getting everyone's gut reactions up-front helps eliminate the emotional component when applying the other thinking skills.</li>
          <li>Obtaining them after all angles have been evaluated is useful in achieving consensus during decision-making.</li>
          <li>Bracketing the session with emotional thinking helps measure the degree to which the non-emotional disucssion changed perceptions.</li>
        </ul>
        <p>Dealing with emotional reactions out in the open before and after a non-emotional review of the subject often yields surprising changes in perspective. Sometimes it doesn't. The point is; if emotions are not permitted into the process, they will affect decision-making in a covert way.</p>
        <p>Becoming a good emotional thinker requires learning to verbalize emotional responses in non-emotional language. It is also important to state an emotional reaction simply, and avoid justifying it:</p>
        <ul>
          <li>I'm frustrated that this problem has not yet been resolved.</li>
          <li>I'm concerned I may be designing myself out of a job.</li>
          <li>Fear may be influencing your opinion on this topic; once you've seen all the details, I think you'll agree it won't result in as many hassles as you anticipate.</li>
          <li>I have a lot invested in learning Microsoft technologies and I am concerned that I will be throwing that away when we move to Java.</li>
          <li>I don't know a lot about .Net technology and I'm skeptical it will produce the performance we need.</li>
        </ul>
        <p>The artificial flavor of these statements is the core of their value. There is no need to 'read' the people in the room. When using emotional thinking skills, you directly ask for and express emotional positions.</p>
      </div>
      <div id="critical-thinking">
        <h4>Critical Thinking</h4>
        <p>Unfortunately many people understand critical thinking to mean that any answer not 100% correct, must be 100% wrong.</p>
        <p>Unlike problems faced in the classroom, real world problems rarely have a single, correct answer. Trade-offs are required in decisions we make every day.</p>
        <p>The degree to which an answer is 'correct' is really measured by how well it helps you achieve your goals, while at the same time minimizing the potential consequences.</p>
        <p><dfn>Critical thinking</dfn> points out contradictions and inconsistencies, question assumptions, validates conclusions, and assesses risks. It points out both the real and potential drawbacks of an idea.</p>
        <p>The tendency to be a critical thinker is actually rooted in the physiology of the brain. The <dfn>mismatch mechanism</dfn> describes a neurological reaction producing a feeling of uneasiness when we encounter a situation not in-line with our expectations. It encourages us to stay in our comfort zone and avoid the unknown. Avoiding the unknown helps ensure we don't make mistakes. In wild animals, this reflex is a key aspect in survival.</p>
        <p>Succeeding at problem solving however, often requires the use of previously untried tactics. In short, problem-solving requires a certain amount of risk-taking. By pushing us to remain in our comfort zone, critical thinking can often result in repeating the same mistakes again and again.</p>
        <p>Most people apply their critical thinking skills differently, depending on who's idea we're looking at:</p>
        <ul>
          <li>We eagerly point out the shortcomings of other people's ideas, while overlooking the benefits,</li>
          <li>We focus on the benefits of our own ideas while overlooking the shortcomings.</li>
        </ul>
        <p>Becoming a good critical thinker requires you to:</p>
        <ol>
          <li>Criticize your own ideas. This helps you:
            <ul>
              <li>Develop a balanced view of them,</li>
              <li>Gain confidence in them: Making an honest attempt to knock down your own ideas and failing will leave you better prepared to defend them in front of an audience</li>
            </ul>
          </li>
          <li>Avoid overusing critical thinking:
            <ul>
              <li>It impedes progress towards innovative solutions.</li>
            </ul>
          </li>
        </ol>
        <p>The goal of critical thinking is not to <em>eliminate</em> ideas, but to identify their potential shortcomings. Ideas are not eliminated until decision-making time, during which emotional thinking is often the major skill employed.</p>
        <p>Research shows critical thinking is often used as a thinly veiled defensive response rather than an unbiased evaluation of the legitimate drawbacks of an idea. In other words, criticisms are often used to justify an already-existing emotional opinion of an idea. Use critical thinking as a tool, rather than a weapon.</p>
        <p>When critical thinking controls the process, an idea that may be 90% correct is often discarded wholesale based on the 10% that's incorrect.</p>
      </div>
      <div id="optimistic-thinking">
        <h4>Optimistic thinking</h4>
        <p>This is the opposite of critical thinking. It points out both the real and potential benefits of an idea.</p>
        <p>Studies of highly successful people show they have in common an overwhelming desire to make things happen, and are not content to wait for a good idea or opportunity to come along. Entrepreneurs are especially good at finding powerful positive points that are not at all obvious on the surface.</p>
        <p>In movie-making the expression <dfn>willing suspension of disbelief</dfn> describes the effect in which movie goers overlook flaws in a story in order to enjoy it. Movies don't need to obey all the laws of physics; they just need to be plausible. Movies are like magic tricks - they only 'work' if you look at them from the right angle. In both cases, the audience suspends criticism long enough to gain entertainment value.</p>
        <p>Similarly, optimistic thinking requires you to suspend criticism long enough to evaluate the potential benefits of an idea. It is a deliberate search for the potential positive aspects of an idea. Becoming a good optimistic thinker requires you to ask two key questions;</p>
        <ul>
          <li>Under what conditions would this idea work?</li>
          <li>If the idea could be made to work, what would be the benefits?</li>
        </ul>
        <p>Optimistic thinking requires you actively search for the positive side of <em>other people's ideas</em>, not just your own.</p>
        <p>Potential benefits fuel lateral thinking. While optimistic thinking identifies them, lateral thinking finds ways to create the conditions under which they can be realized.</p>
        <p>Avoid simplistic good/bad evaluations. It's not enough to identify a benefit and then either accept or dismiss it. Rate the potential for a benefit to be realized, say on a scale of 1-5. A potential benefit may be a long shot, but it must still be on the list until it can either be realized by lateral thinking, or eliminated during decision-making.</p>
      </div>
      <div id="vertical-thinking">
        <h4>Vertical Thinking</h4>
        <p>This skill keeps the problem solving process moving towards a goal. It is the skill needed to ensure goals are reached in an acceptable timeframe. Applying vertical thinking skills can be viewed as putting on your "administrative hat".</p>
        <p>The focus of vertical thinking is to:</p>
        <ul>
          <li>Define the purpose & goals of the working session,</li>
          <li>Collect, organize, and summarize facts, ideas, positives, negatives, unknowns,</li>
          <li>Ensure a parallel rather than a haphazard thought process is followed,</li>
          <li>Collect emotional responses both before and after the working session.</li>
        </ul>
        <p>The expression <dfn>analysis paralysis</dfn> describes the situation in which a problem is never solved because people are so concerned with finding the <em>optimal solution</em> they never deliver <em>any solution</em> at all. One goal of vertical thinking is to stop this from happening.</p>
        <p>On the other hand, overly strong vertical thinkers tend towards the other end of the spectrum; they stop searching for alternatives as soon as the first workable solution is identified.</p>
        <p>The most difficult part of becoming a good vertical thinker is learning when not to be one. If your first reaction to every idea or suggestion is "We don't have time for that", you might be overusing vertical thinking.</p>
        <p>Overused vertical thinking skills impede progress because the path to a solution is rarely a straight line; sidetracks are necessary to ensure potentially innovative and superior solutions are not overlooked - even if a workable solution is already available.</p>
        <p>When confronted with a familiar problem, the knee-jerk response is often to reuse a solution that has been applied in the past without first looking at potential alternatives. There are several downsides to this approach:</p>
        <ul>
          <li>The old solution may have flaws; reusing it results in repeating old mistakes,</li>
          <li>The new problem may not be identical to the old problem; the old solution may need modification to be a good fit.</li>
          <li>Newer and better alternatives may have become available since the last time the problem was solved.</li>
        </ul>
        <p>While sidetracking is an important part of problem solving, so is staying on a timetable. The goal of vertical thinking is to <em>control</em> sidetracking, not <em>eliminate</em> it.</p>
        <p>Remember that parallel thinking requires everyone in the working session look at the problem from the same angle at the same time; everyone involved must take responsibility for keeping the problem solving process on track.</p>
      </div>
      <div id="lateral-thinking">
        <h4>Lateral thinking</h4>
        <p>This is where creative thought happens. The goal is to produce lots of ideas, even if their relevance to the goal is not immediately clear.</p>
        <p>Lateral thinking collaborates with the other thinking skills to:</p>
        <ul>
          <li>Search for alternatives and new ideas,</li>
          <li>Modify ideas to make them relevant to the goal,</li>
          <li>Identify potential solutions for an idea's shortcomings,</li>
          <li>Find ways to realize an idea's potential benefits</li>
          <li>Produce 'movement' even if it's not immediately in the right direction,</li>
          <li>Collect ideas, not critique them.</li>
        </ul>
        <p>In most meetings, a small number of people generate ideas while the rest of the group sit back waiting to pounce on those ideas. People often avoid expressing innovative ideas because past experience has taught that others will immediately shoot them down.</p>
        <p>In a well-run lateral thinking session, everyone is responsible for generating ideas. To avoid discouraging creativity, ideas are not critiqued; instead they are merely catalogued for evaluation at a later time.</p>
        <p>An important technique in lateral thinking is the <dfn>thought experiment</dfn>. A thought experiment explores an idea from different angles even if it seems implausible. The goal is to add details to an idea which is at first only a vague notion. An important aspect of the thought experiment is to ask;</p>
        <ul><li>If we used this idea, what would be the result?</li></ul>
        <p>Lateral thinking emphasizes the quantity of new ideas over the quality of them. <dfn>Harvesting</dfn> is the process of keeping or discarding ideas after they have been evaluated. Ideas are eliminated only during the actual decision-making process which takes place after both critical and optimistic thinking have been applied to them, and after vertical thinking has evaluated their relevance to the goal.</p>
        <p>Don't allow lateral thinking to become an open-ended effort. There is rarely an 'absolute best' alternative. Two ways of controlling lateral thinking include;</p>
        <ul>
          <li>Set a fixed amount of time to spend on it.</li>
          <li>Set a fixed number of ideas to generate.</li>
        </ul>
        <p>However you decide to limit lateral thinking efforts, don't stop until you reach the limit, but don't exceed it either.</p>
      </div>
      <TopLink />
    </div>
    <div id="what-is-creativity">
      <h3>5. What is Creativity?</h3>
      <p>Creativity requires risk-taking, but we are hard-wired to avoid excessive risk. Here we'll look at some of the tools in the lateral thinking toolbox, which are the engine that drives creative thought.</p>
      <div>
        <h4>Eureka</h4>
        <p>The <dfn>eureka effect</dfn> describes the sensation of perception 'snapping' to a new perspective. Both problem solving and humor aim to induce the eureka effect. In problem solving it results in new insights and potential solutions. In humor it results in laughter.</p>
      </div>
      <div>
        <h4>Outside the Box</h4>
        <p>When being asked to solve a problem, most have been told "think outside the box". Changing perspective requires getting outside the box. Once outside, the mismatch mechanism pulls us back in, shifting perspectives in the process.</p>
        <p>So what is this "box"? How did we end up in it? ... and How do we get out of it?</p>
        <p>There is a physiological basis for the box and it is related to the mismatch mechanism. When we hear our name, we assume someone wants our attention; so we turn towards the speaker, or simply call out; "yes?". In most cases this knee-jerk response is the right one. But if we are in a room full of people with the same first name, we quickly learn to press for more information before responding; "me - or him?"</p>
        <p>Learning to ask a question before answering is an example of lateral thinking. The 'box' is the perspective from which we normally look at a situation, and lateral thinking is the process of changing that perspective.</p>
        <p>Problems can't be solved without ideas, and people must come up with the ideas before they can be put to use. This makes lateral thinking one of the most important aspects of problem solving. It is also one of the most difficult skills to learn because people are conditioned to believe creativity is innate; you've got it or you don't, and nothing you can do will change that.</p>
      </div>
      <TopLink />
    </div>
    <div id="creativity-step-by-step">
      <h3>6. Creativity Step-by-Step</h3>
      <p>The lateral thinking toolbox is a set specific techniques to spur creative thought. Like the six hats these tools are often applied repetitively as new information is uncovered.</p>
      <div>
        <h4>Brainstorming</h4>
        <p>Brainstorming produces ideas 'off the top of the head'. While it is a useful first step in lateral thinking, it has the downside of running out of momentum quickly.</p>
      </div>
      <div>
        <h4>Profiling</h4>
        <p>Profiling lists the attributes or characteristics of ideas generated by brainstorming. It's often employed when brainstorming runs out of steam, and is a key skill in comparative analysis (listing the differences and similarities among two or more ideas). It's also a prerequisite for another lateral thinking technique; Generalization.</p>
      </div>
      <div>
        <h4>Generalization</h4>
        <p>Generalization categorizes ideas and attributes about ideas. The key question used to generalize is to ask the question:</p>
        <ul><li>What is the operating principle?</li></ul>
        <p>Generalization often results in additional ideas and attributes. Once an initial set of generalizations is produced, profiling can be applied to the list. Performing a comparative analysis between the generalizations often produces yet more generalizations. Generalization is a key step in producing insights.</p>
      </div>
      <div>
        <h4>Inversion</h4>
        <p>Inversion reverses a principle that resulted from generalization or profiling. It is not uncommon for inversions to appear ridiculous when first proposed. To make an inverted principle useful, apply optimistic thinking;</p>
        <ul>
          <li>Under what conditions would this idea be possible?</li>
          <li>If this could be made to work, what would be the benefits?</li>
        </ul>
        <p>Surprisingly powerful insights often result from inversions.</p>
      </div>
      <div>
        <h4>Provocation</h4>
        <p>Provocation makes intentionally nonsensical statements aimed at 'provoking' new ideas. Good sources of provocative statements include inversions and criticisms.</p>
        <p>While the provocative statement itself may be unworkable, many valid new ideas can be generated by applying profiling, generalization, and optimistic thinking to them.</p>
      </div>
      <div>
        <h4>Randomization</h4>
        <p>Randomization introduces a new, random idea which is unrelated to the ideas being evaluated. To apply randomization, pick a book such as a dictionary. Pick a random page and place your finger randomly on it. The word on which your finger lands is the random idea. Nouns generally work better than verbs. Next apply profiling, generalization, and inversion to the random idea.</p>
        <p>The mismatch mechanism will form conceptual relationships between the random idea and other ideas under scrutiny. The resulting shift in perception can produce startling insights.</p>
      </div>
      <TopLink />
    </div>
    <div id="best-practices">
      <h3>7. Problem Solving Best Practices</h3>
      <p>The success of a problem solving effort is determined by the quality of the information available and the skills of the individuals who evaluate it. Unfortunately information often has a limited shelf life. When goals change, or the environment in which a decision was made changes, answers must change as well. Keeping potential future events in mind while making decisions can improve the chances of success.</p>
      <p>Here are some recommendations for problem solving in a complex and changing environment:</p>
      <div>
        <h4>Limit the Search for Alternatives</h4>
        <p>Place a limit on the search for an <em>optimal</em> answer: In time spent, number of solutions, or both.</p>
        <p>Some problems never go away. Others vanish before they are solved. The effort spent solving a problem should be proportional to the consequences of not solving it, as well as the length of time you will have to live with the answer.</p>
        <p>When pressed for time, produce both a clear short-term solution and a fuzzy long-term solution. Implement the short term solution now, and schedule time to refine and implement the long term solution later.</p>
      </div>
      <div>
        <h4>Identify Alternatives, not Answers</h4>
        <p>Avoid looking for "The Answer". Produce <em>alternatives</em> instead.</p>
        <p>Sometimes conditions change more quickly than anticipated. Sometimes a critical piece of information was missing to begin with. Having a set of alternatives on-hand rather than a single solution improves the odds of success when it comes time to implement.</p>
      </div>
      <div>
        <h4>Plan to Re-Visit the Solution</h4>
        <p>Don't expect the solution to be the final one. Instead, plan to revisit the issue in the future.</p>
        <p>The expression "If it's not broken, don't fix it" may be good advice, but it is not always obvious when something is broken. Long term problems tend to mutate over time. So does the environment in which the problem was first solved. It is even possible for a problem to disappear completely, while the solution put in place to address it remains.</p>
        <p>For high-value problems, periodically to re-evaluate the problem and its solution to determine if modifications are needed.</p>
      </div>
      <TopLink />
    </div>
    <div id="power-of-perception">
      <h3>8. The Power of Perception</h3>
      <div>
        <h4>Money for Nothing</h4>
        <p>Thirty, twelve-year-old school boys were asked to evaluate the proposal:</p>
        <div className="indent">"What do you think of being given $5 per week just for attending school?"</div>
        <p>Unsurprisingly, all of them were in favor of the idea.</p>
        <p>Then the group was asked to list the advantages and disadvantages of the idea, as well as any questions they might have.</p>
        <p>Some of the disadvantages:</p>
        <ul>
          <li>Bullies might steal the money.</li>
          <li>Their parents might decide to stop giving them allowance.</li>
        </ul>
      <p>Some of the open questions:</p>
      <ul>
        <li>Where would the money come from?</li>
        <li>Would there be less money to purchase soccer balls for the school?</li>
      </ul>
      <p>Without answering these questions, or reassuring the children about the potential disadvantages, the boys were again polled for their opinions ... Twenty-nine of the thirty had spontaneously reversed their opinions.</p>
      </div>
      <div>
        <h4>Perception of Competence</h4>
        <p>A doctor evaluates a patient for two minutes before arriving at a diagnosis. Is the doctor:</p>
        <ul>
          <li>Experienced and confident, or</li>
          <li>Hurried and careless?</li>
        </ul>
        <p>A doctor asks questions, orders tests, consults colleagues, and researches books before making a diagnosis. Is the doctor:</p>
        <ul>
          <li>Cautious and thorough, or</li>
          <li>Inexperienced and uncertain?</li>
        </ul>
      </div>
      <div>
        <h4>What's in the Tank?</h4>
        <p>A man is seen emptying beer bottles into the gas tank of an automobile. What's going on?</p>
        <ul>
          <li>He's drunk.</li>
          <li>It's not his car. He's sabotaging it.</li>
          <li>It's a promotional stunt for a beer manufacturer.</li>
          <li>The beer bottles contain gasoline. They were the largest containers he could find.</li>
        </ul>
        <p>How many other alternatives can you think of?</p>
      </div>
      <TopLink />
    </div>
    <div id="case-studies">
      <h3>9. Case Studies</h3>
      <div>
        <h4>Selling Soup</h4>
        <p>A focus group meeting was assembled to generate ideas for selling soup. The group employed the techniques of lateral thinking.</p>
        <p>Here are some attributes from their soup profile list:</p>
        <ul>
          <li>Soups are liquid.</li>
          <li>Soups are eaten with spoons.</li>
          <li>Soups are eaten from bowls.</li>
          <li>Soups are eaten hot.</li>
        </ul>
        <p>To generate new ideas, the group employed inversion. They inverted the second attribute and applied optimistic thinking to make it work:</p>
        <ul><li>Under what conditions would you eat soup with a fork?</li></ul>
        <p>This was how Campbell's invented the 'Chunky Soup' product.</p>
      </div>
      <div>
        <h4>Fighting Crime</h4>
        <p>A community was facing a rising crime rate and needed ideas to combat the problem.</p>
        <p>One of the ideas produced in their initial round of lateral thinking was:</p>
        <ul><li>Hire more police</li></ul>
        <p>Their critical thinking session pointed out:</p>
        <ul><li>There's insufficient funds to hire more police.</li></ul>
        <p>Their second round of lateral thinking employed provocation</p>
        <ul><li>What if everyone was a policeman and didn't have to be paid?</li></ul>
        <p>The result was the Neighborhood Watch program (New York Magazine, April 1971), which is now in use in over twenty thousand communities nationwide.</p>
      </div>
      <div>
        <h4>Pollution Control</h4>
        <p>A legislative body was addressing factory pollution in rivers.</p>
        <p>Their factual thinking session noted:</p>
        <ul><li>Factory water inlets are upstream and waste water outlets are downstream</li></ul>
        <p>A lateral thinking session resulted in the following provocation:</p>
        <ul><li>A polluting factory should be downstream from itself.</li></ul>
        <p>This idea lead to legislation that any factory built alongside a river must have its water input downstream from its own output.</p>
      </div>
      <div>
        <h4>Television Design</h4>
        <p>An idea generating session for television features used randomization to generate new ideas. The random word chosen was "cheese". Profiling 'cheese' resulted in the following list:</p>
        <ul>
          <li>Cheese is a dairy product.</li>
          <li>Cheese is edible.</li>
          <li>Cheese might have holes.</li>
        </ul>
        <p>A provocation was made from the third bullet item:</p>
        <ul><li>What if a television had holes?</li></ul>
        <p>Applying optimistic thinking;</p>
        <ul><li>Under what conditions would there be a benefit to having holes in your television?</li></ul>
        <p>Resulted in;</p>
        <ul><li>There would be a benefit if you could see other things through the holes besides what you were watching on the screen</li></ul>
        <p>A lateral thinking session to expand on this idea and determine how it could be made to work used generalization:</p>
        <ul><li>What else can you see through? How about windows?</li></ul>
        <p>This line of thought resulted in the idea:</p>
        <ul><li>What if we put little windows on the screen that let you watch other channels besides the main program?</li></ul>
      </div>
      <TopLink />
    </div>
  </div>
);

export default function OutsideTheBox() {
  return content;
}

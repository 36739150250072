
class Subject {
    constructor(subjectDoc) {
        this._id = subjectDoc._id;
        this._documentCatalog = subjectDoc.documentCatalog;
        this._display = subjectDoc.display;
        this._document = (subjectDoc.document) ? subjectDoc.document : null;
        this._modules = subjectDoc.modules;
    }
    id() {
        return this._id;
    }
    label() {
        return this._display;
    }
    hasDocument() {
        return this._document != null;
    }
    documentFk() {
        return (this.hasDocument()) ? this._document.fk : null;
    }
    document() {
        return (this.hasDocument()) ? this.documentCatalog()[this._document.fk] : null;
    }
    documentCatalog() {
        return this._documentCatalog;
    }
    allModules() {
        return this._modules;
    }
    findDocument(documentId) {
        return this._documentCatalog[documentId];
    }
};

export default Subject;

import React from "react";
import PCloudVideoView from '../View/PCloudVideoView';
import PCloudHelper from "../Helper/PCloudHelper";

class AboutPage extends React.Component {
  componentDidMount() {
  }
  
  render() {
    const content = (
      <div>
        <div>
          <p>I'm John Festa, the founder. I've been training since 1975. I've taught seminars in the U.S. and Europe and every level of physical ability. I know this stuff works because my instructors actually used what they taught me. So have a few of my students.</p>
          <ul>
            <li style={{marginTop: "0.75em"}}>My first jujutsu teacher trained the U.S. Secret Service (presidential detail), federal and state law enforcement, and created the self-defense curriculum for the U.S. Marshals.</li>
            <li style={{marginTop: "0.75em"}}>My eskrima instructor came from a family that trained the Philippine Marines. He built his personal reputation as a professional fighter in live-blade bolo matches.</li>
            <li style={{marginTop: "0.75em"}}>My second jujutsu teacher was Bruce Lee's grappling instructor.</li>
            <li style={{marginTop: "0.75em"}}>I learned to box training one-on-one with a former NY State Golden Gloves fighter.</li>
            <li style={{marginTop: "0.75em"}}>My karate instructor fundamentally changed Western understanding of this Eastern martial art. He was also a sparring partner of Muhammad Ali.</li>
          </ul>
        </div>
        <div style={{marginTop: "1.0em"}}>
          <PCloudVideoView videoUrl={PCloudHelper.urlVideoContent("trailer/trailer-2020-john-abhjit-voiceover-2k")} />
        </div>
      </div>
    );
    
    return content;
  }
};
 
export default AboutPage;

import React from "react";
import SubjectCatalogManager from '../EntityManager/SubjectCatalogManager';
import {TocLink} from "../View/Links";
import WaitView from "../View/WaitView";
import AppConfig from "../AppConfig";

function SubjectLink(props) {
    let subject = SubjectCatalogManager.catalog().subject(props.subjectId);
    let path = "/learn/".concat(subject.id());
    return <TocLink to={path} label={subject.label()} />
}

function DocumentLink(props) {
    let path = "/learn/".concat(props.documentId);
    return <TocLink to={path} label={props.label} />
}

function CurriculumCatalog(props) {
  let hepiRyuLink = null;
  if(AppConfig.currentConfig !== "prod") {
    hepiRyuLink = <SubjectLink subjectId="hepiryu" />;
  }
  return (
    <div>
      <p>Home-study videos and reading material. Follow at your own pace, at no-cost.</p>
      
      <div id="introduction">
        <h2>Introduction</h2>
        <div className="indent">
          {/* <DocumentLink documentId="martial-history" label="A Bit of History" /> */}
          <DocumentLink documentId="core-arts" label="Jujutsu, Eskrima, Kempo" />
          <DocumentLink documentId="state-of-mind" label="Fighter's State of Mind" />
        </div>
      </div>
      <div id="foundations">
        <h2>Foundation Skills</h2>
        <div className="indent">
          <SubjectLink subjectId="principles" />
          <SubjectLink subjectId="footwork" />
          <SubjectLink subjectId="patterns" />
          <SubjectLink subjectId="tumbling" />
        </div>
      </div>

      <div id="cross-functional-skills">
        <h2>Cross-Functional Skills</h2>
        <div className="indent">
          <SubjectLink subjectId="strikes" />
          <SubjectLink subjectId="locks" />
          <SubjectLink subjectId="grips" />
          <SubjectLink subjectId="takedowns" />
        </div>
      </div>
      
      <div id="putting-it-together">
        <h2>Putting it Together</h2>
        <div className="indent">
          <SubjectLink subjectId="streetboxing" />
          <SubjectLink subjectId="escrima" />
          <SubjectLink subjectId="walking-stick" />
        </div>
      </div>
      
      <div id="academic-topics">
        <h2>Academic Topics</h2>
        <div className="indent">
          <DocumentLink documentId="martial-science" label="Martial Science" />
          <SubjectLink subjectId="kempo" />
          {hepiRyuLink}
        </div>
      </div>
    </div>
  );
}

export default class CurriculumCatalogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCatalogLoaded: false };
  }
  
  componentDidMount() {
    SubjectCatalogManager.waitAllLoaded().then( () => this.setState({isCatalogLoaded: true}) );
  }

  render() {
    return (this.state.isCatalogLoaded === true)
      ? <CurriculumCatalog />
      : <WaitView />;
  }
};

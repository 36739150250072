import Video from './Video.js';

class VideoCatalog {

    _videos = [];

    constructor(catalogDoc) {
        catalogDoc.forEach( videoDoc => {
            this.videos().push(new Video(videoDoc)) 
        });
    }
    videos() {
        return this._videos;
    }
    video(idStr) {
        return this.videos().find( video => video.id() === idStr );
    }
    hasVideo(idStr) {
        return this.videos().findIndex( video => video.id() === idStr ) > -1
    }
    addVideo(video) {
        let index = this.videos().findIndex( ele => ele.id() === video.id() );
        if(index > -1) {
            this.videos()[index] = video;
        } else {
            this.videos().push(video);
        }
    }
};

export default VideoCatalog;

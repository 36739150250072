import Logger from '../../Helper/Logger'
import StitchSession from './StitchSession'

class _SubjectCatalogGateway {

    _logger = new Logger("SubjectCatalogGateway");

    fetchSubject(subjectId) {
        return new Promise( (resolve, reject) => {
            StitchSession.instance()
            .then( (client) => {
                client.callFunction('fetchCurriculumPage', [subjectId])
                .then(function(result) {
                    if(result.success) {
                        resolve(result);
                    }
                    else {
                        this._logger.error('User Error: ', result);
                        reject(result);
                    }
                })
                .catch( (err) => { this._logger.error('Exception', err); });
            })
        })
    }
};

const SubjectCatalogGateway = new _SubjectCatalogGateway();
export default SubjectCatalogGateway;

import Logger from '../../Helper/Logger'
import StitchSession from './StitchSession'

class _VideoCatalogGateway {

    _logger = new Logger("VideoCatalogGateway");

    fetchCatalog() {
        return new Promise( (resolve, reject) => {
            StitchSession.instance()
            .then( (client) => {
                client.callFunction('fetchVideoUrls')
                .then( result => {
                    if(result.success) {
                        resolve(result);
                    } else {
                        this._logger.error("Fetch Error: ", result);
                        reject(result);
                    }
                })
                .catch( (err) => {
                    this._logger.error("Fetch Exception: ", err);
                    reject(err);
                });
            });
        });
    }
};

const VideoCatalogGateway = new _VideoCatalogGateway();
export default VideoCatalogGateway;

import DomHelper from '../../Helper/DomHelper.js';
import AppConfig from '../../AppConfig.js';

class FauxClicky {
    log(arg) {
        console.log(arg);
    }
}

class _ClickySession {
    
    _clickyApi = null;

    _isInitialized() {
        return (this._clickyApi != null);
    }

    _waitForClicky(completeFunc) {
        if("clicky" in window) {
            completeFunc(window["clicky"]);
        } else {
            setTimeout(() => { this._waitForClicky(completeFunc); }, 100);
        }
    }

    initialize() {
        return new Promise( (resolve, reject) => {
            if(this._isInitialized()) {
                resolve();
            } else {
                let config = AppConfig.config("clicky");
                if(config.siteId != null) {
                    window["clicky_site_ids"] = [config.siteId];
                    DomHelper.injectScript(config.apiUrl);
                    this._waitForClicky( clickyApi => {
                        this._clickyApi = clickyApi;
                        resolve();
                    });
                } else {
                    this._clickyApi = new FauxClicky();
                    resolve();
                }
            }
        });
    }

    _waitForInitialize(completeFunc) {
        if(this._isInitialized()) {
            completeFunc(this._clickyApi);
        } else {
            setTimeout(() => { this._waitForInitialize(completeFunc); }, 100);
        }
    }

    clicky() {
        return new Promise( (resolve, reject) => {
            this._waitForInitialize(clickyApi => resolve(clickyApi));
        });
    }
};

var ClickySession = new _ClickySession();
export default ClickySession;

import React from "react";
import axios from "axios";
import Logger from "../Helper/Logger.js";
import TelemetryHelper from "../Helper/TelemetryHelper";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      submitted: false, 
      contact: 'phone', 
      name: '', 
      phone: '', 
      email: '', 
      message: '' 
    };
  }

  FormSubmittedDiv() {
    let content = (
      <div style={{borderWidth: '1px', borderStyle: 'solid', borderRadius: '5px', padding: '1em'}}>
        <p>
          Thanks for reaching out!
          <br />
          You should hear back in a few hours!
        </p>
        <p style={{paddingLeft: '1em'}}>
          -John
          <br />
          <a href='tel://732-482-1378'>732-482-1378</a>
          <br />
          <a href='mailto://reflexfightingarts@gmail.com'>reflexfightingarts@gmail.com</a>
        </p>
        <div style={{textAlign: 'center'}}>
          <button type='button' onClick={() => this.setState({submitted: false})}>Back to Contact Page</button>
        </div>
      </div>
    );

    return content;
  } 

  FormDiv() {
    const contact = this.state.contact;
    let input;
    if(contact ==='phone')
      input = (
        <input type='tel' name='phone' id='phone' 
              placeholder='Your Phone' required={true}
              onChange={this._phoneChangeHandler} 
             pattern="^[0-9-+\s()]*" />
      );
    else
      input = (
        <input type='email' name='email' id='email' 
              placeholder='Your Email' required={true}
              onChange={this._emailChangeHandler} />
      );

    let content = (
      <form id='the-contact-form' onSubmit={this._submitHandler}>
        <div>
          <label htmlFor='name'>Name</label>
          <div>
            <input type='text' name='name' id='name' 
                    required placeholder='Your Name'
                    onChange={this._nameChangeHandler}></input>
          </div>
        </div>
        <div className='radio'>
          <label>
            Phone
            <input type='radio' id='phoneRadio' name='contactRadio' value='phone'
                    checked={this.state.contact === 'phone'}
                    onChange={this._radioChangeHandler} />
          </label>
          &nbsp;
          &nbsp;
          &nbsp;
          <label>
            Email
            <input type='radio' id='emailRadio' name='contactRadio' value='email'
                    checked={this.state.contact === 'email'}
                    onChange={this._radioChangeHandler} />
          </label>
        </div>
        <div>
          {input}
        </div>
        <div>
          <label htmlFor='message'>Message</label>
          <div>
            <textarea name='message' id='message' 
                      maxLength='200' required placeholder='Your Message'
                      onChange={this._messageChangeHandler}></textarea>
          </div>
        </div>
        <div>
          <input type='submit' value='Send'></input>
        </div>
      </form>
    );
    
    return content;

  } 
  _nameChangeHandler = (event) => {
    this.setState( { name: event.target.value} );
  }
  
  _phoneChangeHandler = (event) => {
    this.setState({ phone: event.target.value});
  }
  
  _emailChangeHandler = (event) => {
    this.setState({ email: event.target.value});
  }
  
  _messageChangeHandler = (event) => {
    this.setState({ message: event.target.value});
  }
  
  _radioChangeHandler = (event) => {
      this.setState({ contact: event.target.value });
  }
  
  _submitHandler = (event) => {
    TelemetryHelper.log("/contact/submit");
    
    event.preventDefault();
    const logger = new Logger('ContactForm');
    const contact = (this.state.contact === 'phone') ? this.state.phone : this.state.email; 
    const data = {
      username: this.state.name,
      icon_emoji: ':incoming_envelope:',
      text: [contact, this.state.message].join('\n')
    };
    axios.post(
      'https://hooks.slack.com/services/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', 
      JSON.stringify(data), 
      {
        withCredentials: false,
        transformRequest: [
          (data, headers) => { 
            delete headers.post["Content-Type"];
            return data;
          }
        ]
      }
    ).then( response => {

    }).catch( error => {
      logger.error(error);
    });
    event.target.reset();
    this.setState({ submitted: true });
  }
  
  render() {
    return (true === this.state.submitted)
      ? this.FormSubmittedDiv()
      : (
        <div>
          <p>For information, call:</p>
          <div class="indent">
            <a href='tel://732-482-1378'>732-482-1378</a>
          </div>
          <p>Or leave a message:</p>
          <div className="indent">
            {this.FormDiv()}
          </div>
        </div>
      );
  }
};
 
class ContactPage extends React.Component {
  
  componentDidMount() {
  }
  
  render() {
    const content = (
      <div>
          <p>Reflex Fighting Arts currently offers private instruction only. Prices are affordable, classes are conducted on your schedule and the location is flexible ... I can come to you.</p>
          <ContactForm />
      </div>
    );
    
    return content;
  }
}
 
export default ContactPage;

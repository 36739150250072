
const DomHelper = {
    injectScript: (urlStr) => {
        const script = document.createElement('script');
        script.src = urlStr;
        script.async = true;
        document.body.appendChild(script);
    }
};

export default DomHelper;

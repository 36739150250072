import Logger from '../../Helper/Logger'
import StitchSession from './StitchSession'

class _HtmlContentGateway {

    fetchHtml(htmlUrl) {
        return new Promise( (resolve, reject) => {
            let logger = new Logger("HtmlContentGateway.fetch");

            StitchSession.instance()
            .then( (client) => {
                client.callFunction("fetchVideoText", [htmlUrl])
                .then( (result) => {
                    if(result.success) {
                        resolve(result);
                    }
                    else {
                        if(result.text.startsWith("404")) {
                            /*NOP: This just means there's not description text for this video*/
                        } else {
                            logger.error(result);
                        }
                    }
                })
                .catch( (err) => {
                    logger.error(err);
                    reject(err);
                });
            });
        });
    }
};

const HtmlContentGateway = new _HtmlContentGateway();
export default HtmlContentGateway;

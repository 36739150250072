import './App.css';
import './FightReflex.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch
} from "react-router-dom";
import ClickySession from "./Service/Clicky/ClickySession";
import StitchSession from "./Service/MongoDB/StitchSession";
import HomePage from './Pages/HomePage.js';
import AboutPage from './Pages/AboutPage.js';
import ContactPage from './Pages/ContactPage.js';
import CurriculumCatalogPage from './Pages/CurriculumCatalogPage.js';
import CurriculumSubjectPage from './Pages/CurriculumSubjectPage.js';
import CurriculumVideoPage from './Pages/CurriculumVideoPage.js';
import CurriculumDocumentPage from './Pages/CurriculumDocumentPage.js';
import VideoCatalogManager from "./EntityManager/VideoCatalogManager";
import SubjectCatalogManager from "./EntityManager/SubjectCatalogManager";
import MartialScienceDoc from "./Documents/MartialScienceDoc";
import StateOfMindDoc from "./Documents/StateOfMindDoc";
import CoreArtsDoc from "./Documents/CoreArtsDoc";

export const AppNavigation = () => (
  <div className="table menu" 
      style={{padding: "20pt 5pt 10pt 5pt"}}
  >
    <div id="menuMain">
      <div><NavLink exact activeClassName='NavLinkClassActive' className='NavLinkClass' to="/">Home</NavLink></div>
      <div><div>&nbsp;</div></div>
      <div><NavLink exact activeClassName='NavLinkClassActive' className='NavLinkClass' to="/about" >About</NavLink></div>
      <div><div>&nbsp;</div></div>
      <div><NavLink exact activeClassName='NavLinkClassActive' className='NavLinkClass' to="/contact">Contact</NavLink></div>
      {/* <div><div>&nbsp;</div></div> */}
      {/* <div><NavLink       activeClassName='NavLinkClassActive' className='NavLinkClass' to="/learn">Learn</NavLink></div> */}
    </div>
  </div>
);

export const AppRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomePage}/>
    <Route exact path="/about" component={AboutPage}/>
    <Route exact path="/contact" component={ContactPage}/>
    <Route exact path="/learn" component={CurriculumCatalogPage}/>
    <Route exact path="/learn/core-arts" component={CoreArtsDoc} />
    <Route exact path="/learn/state-of-mind" component={StateOfMindDoc} />
    <Route exact path="/learn/martial-science" component={MartialScienceDoc} />
    <Route exact path="/learn/martial-science/:topicId" component={MartialScienceDoc} />
    <Route exact path="/learn/:subjectId" component={CurriculumSubjectPage} />
    <Route exact path="/learn/:subjectId/video/:videoId" component={CurriculumVideoPage} />
    <Route exact path="/learn/:subjectId/read/:documentId" component={CurriculumDocumentPage} />
  </Switch>
);

class App extends React.Component {
  
  componentDidMount() {
    ClickySession.initialize().then(() => { 
      /*nop*/
    });

    StitchSession.initialize()
    .then(() => {
          VideoCatalogManager.fetchCatalog()
          .then( () => {
            SubjectCatalogManager.fetchAllSubjects()
            .then( () => {
              /*nop*/
            })
            .catch(err => {
              console.log("fetchsubject",err)
            });
          })
          .catch(err => {
            console.log("fetchsubject",err)
          });
    });
  }

  render () {

    const content = (
      <Router>
        <div>
          <div>
            <AppNavigation />
            <div style={{
              display: "table", 
              marginTop: "0", marginBottom: "0.25em",
              fontSize: "2.2em"
            }}>
              <div style={{display: "table-row"}}>
                <div style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  paddingLeft: "0.2em", paddingRight: "0.2em",
                  color: "rgba(179, 146, 2, 1.0)",
                  backgroundColor: "rgba(20, 20, 5, 1.0)"
                }}>
                  Reflex
                </div>
                <div style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  paddingLeft: "0.2em", paddingRight: "0.2em",
                  fontSize: "0.85em",
                  color: "rgba(250, 250, 230, 0.8)", 
                  backgroundColor: "rgba(20, 20, 5, 0.4)"

                }}>
                  Fighting Arts
                </div>
              </div>
            </div>
            <AppRoutes />
          </div>
          <div className="content">
          </div>
          <div style={{textAlign: "center", marginTop: "1.0em"}}>
            <span style={{display: "inline-block"}}>
              <div style={{
                  display: "table",
                  textAlign: 'center',
                  width: '25.0vmin'
              }}>
                <div style={{
                  display: "table-row"
                }}>
                  <div style={{
                    display: "table-cell",
                    backgroundColor: "rgba(250, 250, 240, 0.90)",
                    padding: '3px'
                  }}>
                    <img src='/img/yawara.png' alt=''
                      style={{
                        width: '75%',
                        opacity: "90%", 
                    }}/>
                  </div>
                </div>
                <div style={{
                  display: "table-row",
                  fontWeight: "bold"
                }}>
                  <div style={{
                    display: "table-cell",
                    lineHeight: "1.0",
                    fontSize: "4.4vmin",
                    backgroundColor: "rgba(20, 20, 5, 0.8)"
                  }}>
                    <span style={{
                      display: "inline-block",
                      paddingLeft: "0.05em",
                      color: "rgba(179, 146, 2, 1.0)"
                    }}>
                      Learn
                    </span>
                    <span style={{
                      display: "inline-block",
                      paddingLeft: "0.05em",
                      paddingRight: "0.05em",
                      color: "rgba(250, 250, 230, 0.8)"
                    }}>
                      2
                    </span>
                    <br/>
                    <span style={{
                      display: "inline-block",
                      paddingRight: "0.05em",
                      color: "rgba(179, 146, 2, 1.0)"
                    }}>
                      Adapt
                    </span>
                  </div>
                </div>
              </div>
            </span>
            <p style={{fontSize: "0.80em"}}>&copy; Copyright 2021 ReflexFightingArts.com</p>
          </div>
          <br/>
        </div>
      </Router>
    );
    return content;
  }
}

export default App;

import ClickySession from "../Service/Clicky/ClickySession";

class _TelemetryHelper {
    log(eventStr) {
        ClickySession.clicky().then(clickyApi => {
            console.log(eventStr, clickyApi);
            clickyApi.log(window.location.pathname, eventStr);
        });
    }
}

var TelemetryHelper = new _TelemetryHelper();
export default TelemetryHelper

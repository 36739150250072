import React from "react";
import {HashLink, BreadcrumbSection, RoutedHashLink, BackLink} from "../View/Links";

const content = (
    <div>
        <h2>Fighter's State of Mind</h2>
        <p>Contrary to the pop-culture image of fighters gritting their teeth, pumped-up and angry, the samurai ideal was to fight level-headed and dispassionate. While anger can certainly be an asset in a high-stress situation, uncontrolled emotions lead to flawed decision-making.</p>
        <p>Traditional martial programs trained students in more than just physical fighting skills. Training also included instruction in the psychological aspects of a fight. There's two key states of mind considered optimal for navigating a confrontation:</p>
        <ul>
            <li><HashLink to="#water-mind">Water Mind</HashLink></li>
            <li><HashLink to="#ordinary-mind">Ordinary Mind</HashLink></li>
        </ul>

        <div id="water-mind">
            <h3>Water Mind</h3>
            <p>In Japanese, "mizu no-kokoro". Also called <dfn>No-Mind</dfn>, or "mushin no-shin". This is a meditative state that's calm, focused and alert. The fighter's mind is clear - not preoccupied or fixated on thoughts or emotions, and free to process sensory input instantly, in an almost prescient way.</p>
            <p>In water mind emotions, ego, and conscious thought all disappear. The subconscious mind takes over and the fighter reacts intuitively and without hesitation.</p>
            <p>This is not the same as "blanking out", in which a fighter slips into a blind rage, remembering little of what they did. Instead, no-mind is what most would recognize as "the zone", more like the what's needed to drive in NASCAR at 200+ mph, or engage in an F-18 dogfight -- procedural memory and intuition take over, with the rational mind ready to apply veto power.</p>
            <p>Martial arts have used meditation for centuries as a tool to develop the knee-jerk reaction of slipping into water mind when forced into a physical confrontation.</p>
        </div>

        <div id="ordinary-mind">
            <h3>Ordinary Mind</h3>
            <p>In Japanese it's called "heijoshin". There's two slightly different colloquial meanings of this term:</p>
            <ul>
            <li><em>Presence of Mind</em> - The state in which one is resistant to being startled or taken off-guard.</li>
            <li><em>Confidence</em> - Meaning before one can succeed, one has to first remove all doubts.</li>
            </ul>
            <p>Heijoshin means to have, throughout daily life, a state of mind which is calmly confident, without arrogance, balanced, not easily distracted, and able to quickly and appropriately respond to any situation.</p>
            <p>One of the primary goals of martial arts practice is to develop and maintain this mindset.</p>
        </div>
    </div>
);

export default function StateOfMindDoc(props) {
    return (
        <div id="Fighter's State of Mind">
            <BreadcrumbSection>
                <RoutedHashLink to={"/learn#introduction"}>Introduction</RoutedHashLink>
                {content}
            </BreadcrumbSection>
            <BackLink to="/learn" />
        </div>
    );
}

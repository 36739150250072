
class _SubjectCatalog {

    _subjects = [];

    allSubjects() {
        return this._subjects;
    }
    filterSubjects(subjectIdArr) {
        return this.allSubjects().filter( subject => 
            subjectIdArr.includes(subject.id())
        );
    }
    addSubject(subject) {
        let index = this.allSubjects().findIndex( ele => ele.id() === subject.id())
        if(index < 0) {
            this.allSubjects().push(subject);
        }
    }
    hasSubject(subjectId) {
        const index = this.allSubjects().findIndex( subject => subject.id() === subjectId );
        return (index > -1);
    }
    subject(subjectId) {
        return this.allSubjects().find( subject => subject.id() === subjectId );
    }
};

const SubjectCatalog = new _SubjectCatalog();
export default SubjectCatalog;

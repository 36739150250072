import React from "react";

class PCloudVideoView extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            url: props.videoUrl 
        };
    }

    render() {
        let content = (
            <div className="center">
                <video controls={true} style={{width: "95%"}} preload="metadata" >
                    <source type="video/mp4" src={this.state.url} />
                </video>
            </div>
        );
        return content;
    }
};

export default PCloudVideoView;


class Logger {

    constructor(componentStr) {
        this.componentStr = "[" + componentStr + "]: ";
    }
    
    error(...args) {
        console.error([this.componentStr].concat(args));
    }
    
    warn(...args) {
        console.warn([this.componentStr].concat(args));
    }
    
    info(...args) {
        console.log([this.componentStr].concat(args));
    }
};

export default Logger;

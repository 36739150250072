import VideoCatalogGateway from '../Service/MongoDB/VideoCatalogGateway.js'
import VideoCatalog from '../Entity/VideoCatalog';

class _VideoCatalogManager {

    _catalog = null;

    catalog() {
        return this._catalog;
    }
    isLoaded() {
        return (null != this.catalog());
    }
    waitLoaded() {
        return new Promise( (resolve, reject) => {
            const waitImpl = (callback) => {
                if(this.isLoaded()) {
                    callback(this.catalog());
                } else {
                    setTimeout( () => { waitImpl(callback); }, 100);
                }
            }
            waitImpl(resolve);
        });
    }
    fetchCatalog() {
        return new Promise( (resolve, reject) => {
            if(this.isLoaded()) {
                resolve(this.catalog());
            } else {
                VideoCatalogGateway.fetchCatalog()
                .then( result => {
                    if(this.catalog() === null) {
                        this._catalog = new VideoCatalog(result.docs);
                    }
                    resolve(this.catalog());
                })
                .catch( (err) => {
                    reject(err);
                });
            }
        });
    }
};

var VideoCatalogManager = new _VideoCatalogManager();
export default VideoCatalogManager;

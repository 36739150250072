import React from "react";
import {HashLink, BreadcrumbSection, RoutedHashLink, BackLink, TopLink} from "../View/Links";

const content = (
    <div>
        <h2>Jujutsu, Eskrima, Kempo</h2>
        <p>Reflex Fighting Arts is an integrated system, fusing a number of martial arts. The core arts making up the system have a long and surprisingly fascinating history. Here's the short version:</p>
        <ul>
            <li><HashLink to="jujutsu">Jujutsu & Judo</HashLink></li>
            <li><HashLink to="arnis">Arnis/Eskrima/Kali</HashLink></li>
            <li><HashLink to="kempo">Karate/Kempo</HashLink></li>
        </ul>

        <div id="jujutsu">
            <h3>Japanese Jujutsu & Judo</h3>
            <p>Today, Brazilian "jiu jitsu" is a competitive grappling art focused on ground-fighting in which the winner is declared by submission or choke-out. But it ultimately has it's roots in the close-quarters combat art of the Japanese Samurai which was anything but an empty-hand wrestling art. The name, jiu jitsu, is a misspelling held over from early 20th century translators. Most practitioners believe the name means "gentle art", referring to the emphasis on using technique rather than strength, but even this is a modern invention.</p>
            <p>The real name is "ju jutsu" (with a "u" not "i") and the precise translation is "adaptable technique". The name "adaptable" originated with the art's survival-oriented battlefield mindset. If you needed to use jujutsu, you were facing more heavily armed opponents in a life-or-death situation. The art was adaptable because it used any tactic and any weapon available to defeat the opponent. The philosophy was to "win by any means necessary". Every samurai carried backup weapons including a tactical knife and often a garotte. Grappling was an essential skill needed to control the opponent's weapon while you pulled out your backup weapon and moved in to finish the confrontation. While the art also employed punches and kicks, they were of limited value against an opponent wearing 50-60 lbs of armor.</p>
            <p>Modern jiu jitsu is actually derived from "ju-do" which was in-turn derived from battlefield jujutsu. Judo was created in the late 19th century from battlefield jujutsu which was over 1000 years old. Judo resulted from Japan's efforts to modernize during which traditional martial arts were repurposed to develop character, discipline and fitness among the nation's youth. As a result, the suffix was changed from "jutsu", meaning roughly 'practical skillset' to "do" meaning "The Way" or "path to enlightenment". The translation of "ju" was changed from "adaptable" to "gentle" to support the goal of the new art, which was personal development rather than combat. But judo was essentially a watered-down version of jujutsu designed to be acceptable within the political climate of the era.</p>
            <TopLink />
        </div>
        <div id="arnis">
            <h3>Eskrima/Arnis/Kali</h3>
            <p>These are synonyms for arts from the Philippines now referred to as FMA or 'Filipino Martial Arts'. Many arts have lost practicality as generations of teachers became removed from those who actually used the art on the battlefield. But FMA is unique among ancient martial arts in that it has been employed in actual combat continuously, even in modern times.</p>
            <p>Like all true martial arts, it's primarily a weapons-based fighting system which treats empty hand fighting as a supporting skill. Due to the history of the region, the focus is on weapons that were repurposed from everyday items such as machetes, knives, sticks, bullwhips and headscarves. Over the years, purpose-built weapons have been designed from these everyday items, but the main focus of training is to learn to use any item available as a weapon of opportunity.</p>
            <p>The roots of FMA date back to at least 800 AD but it's origin and history has been mostly lost because the Philippines were subject to colonization by Spain from the 16th to the 19th century and prior to that, the archipelago had no centralized government and therefore few written records.</p>
            <p>Since the Spanish language was forced upon the Filipinos, even the original name of the art has been lost. In the Philippines it's common to call the art by one of it's Spanish names, "arnis de mano". The word "arnis" refers to the protective chest harness worn by Spanish fencers so the name emphasizes the use of the weapon in your hand for self-protection. The name "escrima" (Filipine-ized as "eskrima") is Spanish for "fencing". This name was given to the art by the Spanish Conquistadors who witnessed the Filipinos practice it. Finally the name "kali" is primarily used outside the Philippines. A Tagolog name, it was introduced in the late 20th century as part of the Philippine independence movement as a means to re-assert the art's indigenous origins.</p>
            <TopLink />
        </div>
        <div id="kempo">
            <h3>Karate/Kempo</h3>
            <p>Karate has become vilified by MMA practitioners as ineffective and even useless. But like jujutsu, the modern art bears little resemblance to the historical version practiced in Okinawa behind closed doors for hundreds of years. First off, the blocks, kicks and punches seen in every corner karate school were simply not part of the original art. There's a fascinating backstory here, so stay with me.</p>
            <p>Karate originated in Okinawa around 1400 AD. At that time Okinawa was an independent kingdom, not yet part of Japan. Back then it was called "to-de" meaning "Chinese Hand" because it was brought to the island from southern mainland China during a period of trade and cultural exchange. Initially karate was identical to Chinese kung fu ... because that was the art brought to Okinawa during the cultural exchange. But modern karate doesn't look like kung fu, so what changed? The answer is in two parts.</p>
            <p>First, the region of China from which karate was imported practiced a style of kung fu called "chuan fa" meaning "fist method". In the Okinawan language chuan-fa is pronounced "kemp-po". But the kung fu practiced in China behind closed doors also bears little resemblance to the kung fu seen in Western movies. The reason? It was forbidden to teach Westerners, so the outside world only ever got to see a version of the art that was sanitized for external consumption. </p>
            <p>Authentic chuan`fa consisted of a tight integration of grappling and striking. It was nothing like modern mixed martial arts, mind you. In MMA the striking and grapping are disconnected; one is followed by the other. In kung fu they are integrated; the joint lock and the strike are applied simultaneously. In addition, the joint locks are not submission holds like in modern submission grappling. The locks are ballistic, not static.</p>
            <p>We have insight into what historical karate looked like from two sources. One is a small number of Okinawan masters who were willing to share the authentic art with outsiders. Among them, Hohan Soken and Seiyu Oyata. The other is by looking at other arts which we know were derived from southern style kung fu, specifically Indonesian Silat and Filipino Kali. These empty-hand fighting systems are the most effective in existence. If they weren't they wouldn't be central components of the curriculum taught to special forces all around the world.</p>
            <p>The second reason modern karate doesn't look like kung fu is it was passed to the West by way of Japan. Japan colonized (annexed) Okinawa starting in the 16th century and during Japan's 19th century modernization era, they requested instruction in karate from Okinawa. But the long standing tensions between them resulting from the colonial period didn't motivate the Okinawans to openly share their art. At the same time, Japan was making efforts to transform "martial art" into "martial way", so the streetfight-effective version of karate received the same treatment that battlefield jujutsu received when judo was formulated from it. In other words, the most effective aspects of the art got left behind.</p>
            <TopLink />
        </div>
    </div>
);

export default function CoreArtsDoc(props) {
    return (
        <div id="core-arts">
            <BreadcrumbSection>
                <RoutedHashLink to={"/learn#introduction"}>Introduction</RoutedHashLink>
                {content}
            </BreadcrumbSection>
            <BackLink to="/learn" />
        </div>
    );
}

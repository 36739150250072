import "./CurriculumPage.css";
import React from "react";
import {matchPath} from "react-router";
import SubjectCatalogManager from '../EntityManager/SubjectCatalogManager';
import PCloudHelper from "../Helper/PCloudHelper";
import HtmlTextView from "../View/HtmlTextView";
import {BackLink, BreadcrumbSection, ContentLink} from "../View/Links";
import WaitView from "../View/WaitView";

function CurriclumDocumentView(props) {
  let match = matchPath(props.path, { path: "/learn/:subjectId/read/:documentId"});
  let backPath = "/".concat(match.url.substring(1).split("/").slice(0,2).join("/"));
  let subject = SubjectCatalogManager.catalog().subject(match.params.subjectId);
  let document = subject.findDocument(match.params.documentId);
  let documentUrl = PCloudHelper.urlHtmlContent(document.path);

  return (
    <BreadcrumbSection>
      <ContentLink to={"/learn/".concat(subject.id())}>{subject.label()}</ContentLink>
      <div>
        <h2>{document.display}</h2>
        <div className="hide-doc-header">
          <HtmlTextView textUrl={documentUrl} />
        </div>
      </div>
      <BackLink to={backPath} />
    </BreadcrumbSection>
  );
}

export default class CurriculumDocumentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCatalogLoaded: false };
  }

  componentDidMount() {
    SubjectCatalogManager.waitAllLoaded().then( () => this.setState({isCatalogLoaded: true}) );
  }

  render() {
    if(this.state.isCatalogLoaded !== true) {
      return <WaitView />
    } else {
      return <CurriclumDocumentView path={this.props.location.pathname} />;
    }
  }
};

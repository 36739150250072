import React from "react";
import ReactHtmlParser from "react-html-parser";
import HtmlContentGateway from "../Service/MongoDB/HtmlContentGateway";
import Logger from "../Helper/Logger";

class HtmlTextView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props.textUrl,
            label: props.label,
            isContentLoaded: false,
            content: "",
            waitBehavior: (props.waitBehavior) ? props.waitBehavior : "show-wait"
        }
    }

    componentDidMount() {
        let logger = new Logger("HtmlTextView.componentDidMount");

        HtmlContentGateway.fetchHtml(this.state.url)
        .then( doc => {
            this.setState({
                content: doc.text,
                isContentLoaded: true
            });
        })
        .catch( err => 
            logger.error(err)
        )
    }

    "show-wait" = () => <p>wait for it...</p>;
    "show-label" = () => <h3>{this.state.label}</h3>;
    "show-none" = () => null;
    wait = behavior => this[behavior]();

    render() {
        if(this.state.isContentLoaded) {
            let content = this.state.content;
            if(content && content.length > 0) {
                return (
                    <div>
                        {ReactHtmlParser(content)}
                    </div>
                );
            }
        } else {
            return this.wait(this.state.waitBehavior);
        }
    }
};

export default HtmlTextView;


class Video {
    constructor(videoDoc) {
        this._id = videoDoc._id;
        this._display = videoDoc.display;
        this._path = videoDoc.path;
        this._description = "";
    }
    id() { 
        return this._id; 
    }
    label() { 
        return this._display; 
    }
    path() { 
        return this._path; 
    }
    description() { 
        return this._description; 
    }
    setDescription(descriptionStr) {
        this._description = descriptionStr;
    }
};

export default Video;

import React from 'react';
// import YouTubeVideoView from "../View/YouTubeVideoView";
import PCloudVideoView from '../View/PCloudVideoView';
import PCloudHelper from "../Helper/PCloudHelper";

class HomePage extends React.Component {

  componentDidMount() {
  }
  
  render () {
    const content = (
      <div>
        <div>
            <q>It's about how you confront life's challenges.<br/>&nbsp;&nbsp;Not how you spend your spare time.</q>
        </div>
        <div>
          <p>This unique fusion of Filipino, Japanese and Western martial arts emphasizes practical self-defense. You'll learn skills normally acquired only by those with backgrounds in special forces or executive protection. But it's also holistic, developing attributes useful in everyday life.</p>
          <p>Built upon attribute-building drills that progress from structured to unchoreographed, you'll develop fighting reflexes quickly, but without the injuries that can occur in programs that emphasize competition.</p>
          <div style={{marginTop: "1.0em"}}>
            <PCloudVideoView videoUrl={PCloudHelper.urlVideoContent("trailer/trailer-2019")} />
          </div>
          <div><q>But why should I learn this? I don't believe in violence!</q></div>
          <p>Most of us will never need the physical skills we practice, but that's missing the point. Martial arts trains you stay level-headed under pressure, a useful skill in any setting. It also cultivates physical and mental agility, which is critical to aging gracefully.</p>
          <div><q>Isn't this just kicking and punching? That's boring!</q></div>
          <p>Not really, no. Traditional martial arts was highly technical, requiring knowledge of anatomy, physics, psychology and more. Training will feed your brain as as well as your body.</p>
          <div style={{marginTop: "1.0em"}}>
            <PCloudVideoView videoUrl={PCloudHelper.urlVideoContent("trailer/trailer-2015-2k")} />
          </div>
        </div>
        <div>
          <p>Did I mention it's also an addictive amount of fun and the best stress reliever I know of?</p>
        </div>
      </div>
    )
    
    return content;
  }
}

export default HomePage;

import SubjectCatalogGateway from '../Service/MongoDB/SubjectCatalogGateway'
import SubjectCatalog from '../Entity/SubjectCatalog'
import Subject from '../Entity/Subject'

class _SubjectCatalogManager {

    _catalog = SubjectCatalog;
    _subjectIds = [
        "principles", 
        "patterns", 
        "footwork", 
        "tumbling", 
        "strikes", 
        "locks",
        "takedowns", 
        "grips", 
        "streetboxing", 
        "escrima",
        "walking-stick", 
        "kempo", 
        "hepiryu"
    ];

    catalog() {
        return this._catalog;
    }

    _waitLoadedImpl(idStr, callback) {
        let subject = this.catalog().subject(idStr);
        if(subject) {
            callback(subject);
        } else {
            setTimeout( () => { this._waitLoadedImpl(idStr, callback); }, 100);
        }
    }

    waitLoaded(idStr) {
        return new Promise( (resolve, reject) => {
            this._waitLoadedImpl(idStr, resolve);
        });
    }

    _waitAllLoadedImpl(completeFunc) {
        if(this._allSubjectsLoaded === true) {
            completeFunc()
        } else {
            setTimeout(() => this._waitAllLoadedImpl(completeFunc), 100);
        }

    }
    waitAllLoaded() {
        return new Promise( (resolve, reject) => {
            if(this._allSubjectsLoaded === true) {
                resolve();
            } else {
                this._waitAllLoadedImpl(resolve);
            }
        });
    }
    allLoaded() {
        return this._allSubjectsLoaded;
    }

    fetchSubject(idStr) {
        return new Promise( (resolve, reject) => {
            let subject = this.catalog().subject(idStr);
            if(subject) {
                resolve(subject);
            } else {
                SubjectCatalogGateway.fetchSubject(idStr)
                .then( (result) => {
                    let subject = new Subject(result.doc);
                    this.catalog().addSubject(subject);
                    resolve(subject);
                })
                .catch( (err) => {
                    reject(err);
                });
            }
        });
    }
    
    fetchAllSubjects() {
        return new Promise( (resolve, reject) => {
            
            let promises = [];

            this._subjectIds.forEach( idStr => {
                promises.push(this.fetchSubject(idStr));
            });

            Promise.all(promises)
            .then( (resultArr) => {
                resultArr.forEach( (subjectDoc) => {
                    this.catalog().addSubject(new Subject(subjectDoc));
                });
                let allSubjects = this.catalog().allSubjects();
                let subjects = allSubjects.filter( subject => {
                    let index = allSubjects.findIndex( ele => subject.id() === ele.id() );
                    return (index > -1);
                });
                this._allSubjectsLoaded = true;
                resolve(subjects);
            });
        });
    }
};

var SubjectCatalogManager = new _SubjectCatalogManager();
export default SubjectCatalogManager;
